import { Heading, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { PageContainer } from '~/components/page-container';
import { BrandsList } from '~/pages/brands/brands-list';

export const NoRestaurantsFound = () => {
  return (
    <PageContainer>
      <HStack spacing={10} mb={20}>
        <Image src='/img/cactus-not-found.svg' height={250} width={250} />
        <VStack spacing={8} alignItems='flex-start' color='secondary.primary'>
          <Heading as='h1' fontSize='xl'>
            No Restaurants Found
          </Heading>
          <Text fontSize='1.125rem'>
            It seems we don&apos;t have any restaurants available at the moment.
            <br /> Check out our other brands and deals below!
          </Text>
        </VStack>
      </HStack>
      <BrandsList />
    </PageContainer>
  );
};
