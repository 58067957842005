import { Box, Heading, Input, VStack } from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import { useConfig } from '~/hooks/useConfig/useConfig';
import { useTranslations } from '~/hooks/useTranslations';
import { REGIONS } from '~/lib/constants';
import { formatCurrency } from '~/lib/helpers';

export function GiftCardDenominationInput({ min, max, value, onChange }) {
  const { giftCardTranslation } = useTranslations();
  const config = useConfig();
  const formattedMin = formatCurrency(min, config?.region ?? REGIONS.US);
  const formattedMax = formatCurrency(max, config?.region ?? REGIONS.US);

  return (
    <VStack gap={4} width='100%' justifyContent='flex-start' alignItems='flex-start'>
      <Heading variant='subhead' mb={-1}>
        {giftCardTranslation.inputLabel(formattedMin, formattedMax)}
      </Heading>
      <Box width='100%'>
        <Input
          placeholder='Gift Card Value'
          layerStyle='stacked-field'
          value={Number.isNaN(value) ? undefined : value}
          onChange={onChange}
          type='number'
          min={min}
          max={max}
          step={0}
          width='100%'
          maxWidth='210px'
          my={0}
        />
      </Box>
    </VStack>
  );
}

GiftCardDenominationInput.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
};
