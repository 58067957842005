import { Box, HStack, IconButton, Stack, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const BasePromoCodeButton = ({ code, onClick, promoIcon, copyIcon, copyIconProps }) => {
  // show only first 5 characters of the voucher code
  const maskedCode = code.slice(0, 5);

  return (
    <HStack gap={0} space={0} onClick={onClick} cursor='pointer'>
      <Box
        position='relative'
        px={`calc(1.5rem)`}
        py={`calc(0.5rem - 1px)`}
        border='1px dashed'
        borderRadius='3xl'
        borderRightRadius='0'
        borderRight='none'
        borderColor='gray.200'
        textAlign='center'
        fontWeight={400}
      >
        <Stack direction='row' align='center' gap={2}>
          {promoIcon}
          <Text>{maskedCode}</Text>
          <Box
            position='absolute'
            right={0}
            top={0}
            bottom={0}
            width='65%'
            bgGradient='linear(to-l, white, transparent)'
          />
        </Stack>
      </Box>
      <IconButton
        aria-label='Copy code'
        size='md'
        icon={copyIcon}
        borderRadius='0'
        borderRightRadius='3xl'
        borderRight='1px solid'
        {...copyIconProps}
      />
    </HStack>
  );
};

BasePromoCodeButton.propTypes = {
  code: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  promoIcon: PropTypes.node.isRequired,
  copyIcon: PropTypes.node.isRequired,
  copyIconProps: PropTypes.object,
};
