import { merge } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { ValidationError } from 'yup';
import configFallback from '~/lib/config-fallback.json';
import { BASE_URL, LOCAL_STORAGE_KEYS } from '~/lib/constants';
import { BadStatusError, handleError } from '~/lib/errors';
import { getIsDev, responseStatusIsGood } from '~/lib/helpers';
import { mergeConfig } from './configHelpers';
import { wlConfigV1Schema } from './validationSchema';

const environment = import.meta.env.VITE_ENVIRONMENT ?? 'dev';
const PREVIEW_CONFIG_KEY = 'preview-config';
const isDev = getIsDev();

let cachedConfig;
let isFetching = false;

// load cached config from localStorage if present
try {
  const entry = localStorage.getItem(LOCAL_STORAGE_KEYS.WL_CONFIG);
  if (entry) {
    const parsed = JSON.parse(entry);
    cachedConfig = parsed;
  }
} catch (error) {
  // delete cached config from localStorage if not parseable
  localStorage.removeItem(LOCAL_STORAGE_KEYS.WL_CONFIG);

  if (isDev) {
    console.error(
      'An error was encountered while applying the cached white label config on page load: ',
      error?.message
    );
  }
}

export const useConfig = () => {
  const [config, setConfig] = useState(() => ({
    ...(cachedConfig ?? configFallback),
    isConfigPresent: Boolean(cachedConfig),
    isConfigFresh: false,
    env: environment,
  }));

  /**
   * @description fetch white label configuration from the DB. Return if it conforms to WL config v1.0, else return null.
   * @returns WLConfig | null
   */
  const fetchConfigFromDb = useCallback(async () => {
    try {
      const response = await fetch(`${BASE_URL}/web/v1/content/config`, {
        headers: {
          'Cache-Control': 'no-cache',
          'Publisher-Id': import.meta.env.VITE_PUBLISHER_ID,
        },
      });

      const json = await response.json();
      if (!json?.config) {
        throw new Error('Unrecognized config format');
      }
      const parsed = JSON.parse(json?.config);

      const fetchedConfig = Array.isArray(parsed)
        ? parsed.find((config) => config?.version === '1.0')
        : parsed;
      if (!fetchedConfig) {
        throw new Error('No config of correct version was found.');
      }

      const validationSchema = wlConfigV1Schema;
      const fetchedConfigIsValid = await validationSchema.validate(fetchedConfig);
      if (!fetchedConfigIsValid) {
        throw new Error('Config fetched from DB is not valid');
      }

      return fetchedConfigIsValid ? fetchedConfig : null;
    } catch (error) {
      if (isDev) {
        if (error instanceof ValidationError) {
          console.error('Invalid config version 1.0: ', error?.message);
        } else {
          console.error(
            'An error occurred while fetching the WL config from the DB:',
            error?.message
          );
        }
      }
      return null;
    }
  }, []);

  /**
   * @description fetch white label configuration bundled with the app. Return if it conforms to WL config v1.0, else return null.
   * @returns WLConfig | null
   */
  const fetchConfigFromBundle = useCallback(async () => {
    // unlikely an error will ever occur since we're "fetching" a local static file but just in case
    try {
      const response = await fetch(`${BASE_URL}/config/config.json`, {
        headers: {
          'Cache-Control': 'no-cache',
        },
      });

      if (!responseStatusIsGood(response)) {
        throw new BadStatusError(response);
      }

      const data = await response.json();

      if (data) {
        return data;
      } else {
        throw new Error('No bundled config found');
      }
    } catch (err) {
      /* the one exception where pasing a toast argument is not possible due to this error
         blocking ChakraProvider from loading */
      handleError(null, err, 'Can not load white label config');
      return null;
    }
  }, []);

  /**
   * @description Create WLConfig v1.0-compliant configuration by combining the fallback config
   * with the fetched config (from either the DB or bundle) and any customizations from the query
   * params.
   * @returns WLConfig
   */
  const composeConfig = useCallback((fetchedConfig = {}) => {
    let composedConfig = {
      /* begin with configFallback as base; overwrite with fetched white label config
           wherever properties are found; is recursive */
      ...merge(configFallback, fetchedConfig),
      isConfigPresent: true,
      isConfigFresh: true,
      env: environment,
    };

    if (isDev) {
      // Check for white label config in search params
      const searchParams = new URLSearchParams(window.location.search);
      const previewConfigDiffString = searchParams.get(PREVIEW_CONFIG_KEY);
      let previewConfigDiff;

      try {
        previewConfigDiff = previewConfigDiffString ? JSON.parse(previewConfigDiffString) : null;
      } catch {
        if (isDev) {
          console.error(
            'Invalid white label config found in query params. Unable to show white label preview.'
          );
        }
        previewConfigDiff = '';
      }

      if (previewConfigDiff) {
        // hide header to avoid displaying broken links during preview
        composedConfig.hide_support_header = true;

        composedConfig = mergeConfig(composedConfig, previewConfigDiff);
      }
    }

    return composedConfig;
  }, []);

  /**
   * @description generate a WL Config from the first valid source and store it in local state
   */
  const loadConfig = useCallback(async () => {
    let fetchedConfig = await fetchConfigFromDb();
    if (!fetchedConfig) {
      if (isDev) {
        console.warn('No config found in DB');
      }
      fetchedConfig = await fetchConfigFromBundle();
    }

    const composedConfig = composeConfig(fetchedConfig);

    cachedConfig = composedConfig;
    localStorage.setItem(LOCAL_STORAGE_KEYS.WL_CONFIG, JSON.stringify(composedConfig));
    setConfig(composedConfig);
  }, [fetchConfigFromDb, fetchConfigFromBundle, composeConfig]);

  useEffect(() => {
    if (!config?.isConfigFresh) {
      if (isFetching) {
        return;
      } else {
        isFetching = true;

        loadConfig();
      }
    }
  }, [config?.isConfigFresh, loadConfig]);

  return cachedConfig ?? config;
};
