import {
  Box,
  Button,
  Checkbox,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Select,
  Spacer,
  Text,
  Tooltip,
  useTheme,
  VStack,
} from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import { useRef } from 'react';
import Confetti from 'react-confetti';
import { IoHelpCircleOutline } from 'react-icons/io5';
import CustomModal from '~/components/common/custom-modal/custom-modal';
import { ErrorRow } from '~/components/common/error-row';
import EditIcon from '~/components/icons/edit-icon';
import { useConfig } from '~/hooks/useConfig/useConfig';
import { useTranslations } from '~/hooks/useTranslations';
import { ACCOUNT_TYPE } from '~/lib/constants';
import { formatCurrency, getCurrencySymbol, getRegion } from '~/lib/helpers';

const CONFETTI_HEIGHT = '256px';

const getLastFourDigits = (num) => {
  const accountNumber = num.toString();
  return accountNumber.slice(-4);
};

export default function RedemptionForms({
  values,
  errors,
  showConfetti,
  onNext,
  isLoading,
  isModalOpen,
  onCloseModal,
  handleChange,
  handleBlur,
  stepNumber,
  onGoToStep,
  isSubmitting,
}) {
  const { genericTranslation: t, rewardsTranslation: r } = useTranslations();
  const theme = useTheme();
  const inputRef = useRef(null);
  const confettiContainerRef = useRef(null);

  const config = useConfig();
  const region = getRegion(config);

  const disabledRedemptionAmountForm = !values.redemptionAmount || errors.redemptionAmount;
  const disabledBankInfoForm =
    errors.accountNumber ||
    errors.reAccountNumber ||
    errors.routingNumber ||
    !values.accountHolderName;
  const disabledConfirmForm = !values.acceptedTerms || isSubmitting;

  const stepZero = (
    <>
      <InputGroup>
        <InputLeftElement pointerEvents='none' paddingLeft='10px' paddingTop='17px'>
          {getCurrencySymbol(region)}
        </InputLeftElement>
        <Input
          sx={{ paddingLeft: 8 }}
          name='redemptionAmount'
          layerStyle='sign-in-field'
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder='Amount'
          type='number'
          value={values.redemptionAmount}
        />
      </InputGroup>
      <ErrorRow isPresent={Boolean(errors.redemptionAmount)}>{errors.redemptionAmount}</ErrorRow>
    </>
  );

  const stepOne = (
    <VStack>
      <Text textAlign='center'>{r.enterBankInfo}</Text>
      <VStack align='start' width='100%'>
        <InputGroup ref={inputRef}>
          <Input
            name='accountHolderName'
            layerStyle='sign-in-field'
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={r.accountHolderName}
            value={values.accountHolderName}
          />
          <InputRightElement paddingLeft='10px' paddingTop='17px'>
            <Tooltip
              bg='#828B97'
              hasArrow
              height='36px'
              label={r.accountHolderNameInfo}
              padding='8px'
              portalProps={{ containerRef: inputRef }}
              placement='top'
              borderRadius='8px'
              width='310px'
            >
              <Text>
                <IoHelpCircleOutline size={20} color={theme?.colors?.primary_color} />
              </Text>
            </Tooltip>
          </InputRightElement>
        </InputGroup>
        <Input
          name='routingNumber'
          layerStyle='sign-in-field'
          placeholder={r.routingNumber}
          value={values.routingNumber}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <ErrorRow isPresent={Boolean(errors.routingNumber)}>{errors.routingNumber}</ErrorRow>
        <Input
          name='accountNumber'
          layerStyle='sign-in-field'
          placeholder={r.accountNumber}
          value={values.accountNumber}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <ErrorRow isPresent={Boolean(errors.accountNumber)}>{errors.accountNumber}</ErrorRow>
        <Input
          name='reAccountNumber'
          layerStyle='sign-in-field'
          placeholder={r.reAccountNumber}
          value={values.reAccountNumber}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <ErrorRow isPresent={Boolean(errors.reAccountNumber)}>{errors.reAccountNumber}</ErrorRow>
      </VStack>
      <Select
        defaultValue={values.accountType}
        name='accountType'
        height='58px'
        onChange={handleChange}
      >
        <option value={ACCOUNT_TYPE.SAVINGS}>{r.savingsAccount}</option>
        <option value={ACCOUNT_TYPE.CHECKING}>{r.checkingAccount}</option>
      </Select>
    </VStack>
  );

  const stepTwo = (
    <VStack spacing={4} align='center'>
      <Box layerStyle='bank-details-box' p='20px'>
        <HStack>
          <VStack align='start'>
            <Text textStyle='modal-details-title'>{t.amount}</Text>
            <Text textStyle='modal-details-info'>
              {formatCurrency(values.redemptionAmount, region)}
            </Text>
          </VStack>
          <Spacer />
          <HStack onClick={() => onGoToStep(0)}>
            <EditIcon />
            <Text color='primary_color' cursor='pointer'>
              {t.edit}
            </Text>
          </HStack>
        </HStack>
        <VStack spacing={4} align='start'>
          <VStack align='start'>
            <Text textStyle='modal-details-title'>{t.account}</Text>
            <Text textStyle='modal-details-info'>
              {`Ending in ${getLastFourDigits(values.accountNumber)}`}
            </Text>
          </VStack>
          <VStack align='start'>
            <Text textStyle='modal-details-title'>{t.date}</Text>
            <Text textStyle='modal-details-info'>{r.daysToReceive}</Text>
          </VStack>
        </VStack>
      </Box>
      <Box alignItems='start' w={['310px', '360px']}>
        <Checkbox name='acceptedTerms' onChange={handleChange} onBlur={handleBlur}>
          {r.authConfirmation}
        </Checkbox>
      </Box>
    </VStack>
  );

  const stepThree = (
    <VStack>
      <Image alt='icon' src='/icons/confetti.png' />
    </VStack>
  );

  const renderContent = (step) => {
    switch (step) {
      case 0:
        return {
          header: <Text textStyle='modal-title'>{r.howMuchWithdraw}</Text>,
          body: stepZero,
          footer: (
            <Button onClick={onNext} isDisabled={disabledRedemptionAmountForm} width='100%'>
              {t.continue}
            </Button>
          ),
        };
      case 1:
        return {
          header: <Text textStyle='modal-title'>{r.whereTransfer}</Text>,
          body: stepOne,
          footer: (
            <Button onClick={onNext} isDisabled={disabledBankInfoForm} width='100%'>
              {t.continue}
            </Button>
          ),
        };
      case 2:
        return {
          header: <Text textStyle='modal-title'>{r.confirmTransfer}</Text>,
          body: stepTwo,
          footer: (
            <Button
              isLoading={isLoading}
              onClick={onNext}
              isDisabled={disabledConfirmForm}
              width='100%'
            >
              {t.confirm}
            </Button>
          ),
        };
      case 3:
        return {
          preHeaderContent: showConfetti ? (
            <Box
              sx={{
                borderRadius: '24px',
                position: 'absolute',
                top: '0px',
                left: '0px',
                width: '100%',
                overflow: 'hidden',
                height: CONFETTI_HEIGHT,
              }}
            >
              <Confetti
                width={confettiContainerRef.current?.clientWidth || undefined}
                height={CONFETTI_HEIGHT}
                numberOfPieces={500}
                recycle={true}
                onConfettiComplete={onCloseModal}
              />
            </Box>
          ) : null,
          header: <Text textStyle='modal-title'>{r.withdrawalProcessing}</Text>,
          body: stepThree,
          footer: <Text textAlign='center'>{r.withdrawalEmail}</Text>,
        };
    }
  };

  const { preHeaderContent, header, body, footer } = renderContent(stepNumber);
  return isModalOpen ? (
    <CustomModal
      preHeaderContent={preHeaderContent}
      header={header}
      footer={footer}
      isOpen={isModalOpen}
      onClose={onCloseModal}
    >
      {body}
    </CustomModal>
  ) : null;
}
RedemptionForms.propTypes = {
  isSubmitting: PropTypes.bool,
  availableCashback: PropTypes.number,
  onNext: PropTypes.func,
  onCloseModal: PropTypes.func,
  isModalOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  showConfetti: PropTypes.bool,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  stepNumber: PropTypes.number,
  values: PropTypes.object,
  errors: PropTypes.object,
  onGoToStep: PropTypes.func,
};
