import { Box, Stack, Text, useDisclosure } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { PromoCodeButton } from '~/components/promo-code-button';
import { SignUpToActivate } from '~/components/sign-up-to-activate/sign-up-to-activate';
import { useAccountState } from '~/hooks/account/useAccountState';
import { PromoCodeModal } from './promo-code-modal';
import { PromoDetails } from './promo-details';

const getOfferTitle = (text) => {
  const sentences = text.split(/[.!?](?:\s|$)/);
  if (sentences.length) {
    return sentences[0];
  }
  return text;
};

export const PromoCard = ({ offer }) => {
  const { isLoggedIn } = useAccountState();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handlePromoCodeClick = () => {
    if (offer.voucher_code && typeof navigator !== 'undefined') {
      navigator.clipboard.writeText(offer.voucher_code);
    }
    onOpen();
  };

  const title = getOfferTitle(offer.description);
  return (
    <Stack
      gap={4}
      px={5}
      py={4}
      borderWidth='1px'
      borderRadius='lg'
      borderColor='gray.200'
      w='full'
    >
      <Box>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          justifyContent='space-between'
          spacing={{ base: 4, md: 40 }}
          align='stretch'
        >
          <Text fontWeight={500}>{title}</Text>
          {isLoggedIn ? (
            <>
              <PromoCodeButton code={offer.voucher_code} onClick={handlePromoCodeClick} />
              <PromoCodeModal
                isOpen={isOpen}
                onClose={onClose}
                code={offer.voucher_code}
                trackingUrl={offer.tracking_url}
              />
            </>
          ) : (
            <SignUpToActivate />
          )}
        </Stack>
      </Box>
      <Box borderTop='1px dashed' borderColor='gray.200' pt={3}>
        <PromoDetails offer={offer} />
      </Box>
    </Stack>
  );
};

PromoCard.propTypes = {
  offer: PropTypes.object.isRequired,
};
