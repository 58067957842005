import { Button } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useModalAction } from '~/hooks/modal/useModalAction';
import { useConfig } from '~/hooks/useConfig/useConfig';
import { MODALS } from '~/lib/constants';
import { goUrl, neutralizeEvent } from '~/lib/helpers';

export const SignUpToActivate = () => {
  const { openModal } = useModalAction();
  const {
    account: { sign_up_url },
  } = useConfig();

  const openSignUp = useCallback(
    (e) => {
      neutralizeEvent(e);
      if (sign_up_url) {
        goUrl(sign_up_url);
      } else {
        openModal(MODALS.SIGN_UP);
      }
    },
    [sign_up_url, openModal]
  );

  return (
    <Button variant='baseStyle' px={[6, 6, 12]} onClick={openSignUp}>
      Sign up to activate
    </Button>
  );
};

export default SignUpToActivate;
