import { Icon } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const UserCrownIcon = ({ size = 4, color = 'currentColor' }) => {
  return (
    <Icon width='auto' height={size} viewBox='0 0 14 16' fill='none' color={color}>
      <svg
        width='14'
        height='16'
        viewBox='0 0 14 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M7.4375 0.15625L8.46875 0.84375C8.625 0.9375 8.84375 0.9375 9 0.84375L10.0938 0.09375C10.1875 0.03125 10.2812 0 10.375 0H10.5C10.75 0 11 0.25 11 0.5V4V4.5C11 6.71875 9.1875 8.5 7 8.5C4.78125 8.5 3 6.71875 3 4.5V4V0.5C3 0.25 3.21875 0 3.5 0H3.59375C3.6875 0 3.78125 0.03125 3.875 0.09375L4.96875 0.84375C5.125 0.9375 5.34375 0.9375 5.5 0.84375L6.53125 0.15625C6.65625 0.0625 6.8125 0 7 0C7.15625 0 7.3125 0.0625 7.4375 0.15625ZM10 3.5V1.375L9.5625 1.65625C9.0625 2 8.40625 2 7.90625 1.65625L7 1.0625L6.0625 1.65625C5.5625 2 4.90625 2 4.40625 1.65625L4 1.375V3.5H10ZM4 4.5C4 6.15625 5.34375 7.5 7 7.5C8.65625 7.5 10 6.15625 10 4.5H4ZM5.25 10.5C2.875 10.5 1 12.4062 1 14.75V15H13V14.75C13 12.4062 11.0938 10.5 8.75 10.5H5.25ZM5.25 9.5H8.75C11.625 9.5 14 11.875 14 14.75V15C14 15.5625 13.5312 16 13 16H1C0.4375 16 0 15.5625 0 15V14.75C0 11.875 2.34375 9.5 5.25 9.5Z'
          fill='currentColor'
        />
      </svg>
    </Icon>
  );
};

UserCrownIcon.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
};

export default UserCrownIcon;
