import { Icon } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const PlusIcon = ({ size = 4, color = 'currentColor' }) => {
  return (
    <Icon width='auto' height={size} viewBox='0 0 14 15' fill='none' color={color}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='14'
        height='15'
        viewBox='0 0 14 15'
        fill='none'
      >
        <path
          d='M7.45312 3.71973V6.72754H10.4609C10.7053 6.72754 10.9121 6.93433 10.9121 7.17871C10.9121 7.44189 10.7053 7.62988 10.4609 7.62988H7.45312V10.6377C7.45312 10.9009 7.24634 11.0889 7.00195 11.0889C6.73877 11.0889 6.55078 10.9009 6.55078 10.6377V7.62988H3.54297C3.27979 7.62988 3.0918 7.44189 3.0918 7.17871C3.0918 6.93433 3.27979 6.72754 3.54297 6.72754H6.55078V3.71973C6.55078 3.47534 6.73877 3.26855 7.00195 3.26855C7.24634 3.26855 7.45312 3.47534 7.45312 3.71973Z'
          fill='currentColor'
        />
      </svg>
    </Icon>
  );
};

PlusIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default PlusIcon;
