import { Stack, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { Tooltip } from '~/components/tool-tip/tool-tip';

export const MinSpend = ({ amount }) => {
  return (
    <Stack direction='row' gap={1} alignItems='center'>
      <Text>Min. spend ${amount}</Text>
      <Tooltip TooltipText={`Transaction needs to be $${amount} or more to qualify for cashback`} />
    </Stack>
  );
};

MinSpend.propTypes = {
  amount: PropTypes.number.isRequired,
};
