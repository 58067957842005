import { Icon } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const MinusIcon = ({ size = 4, color = 'currentColor' }) => {
  return (
    <Icon width='auto' height={size} viewBox='0 0 14 15' fill='none' color={color}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='14'
        height='15'
        viewBox='0 0 14 15'
        fill='none'
      >
        <path
          d='M10.9121 7.02051C10.9121 7.28369 10.7053 7.47168 10.4609 7.47168H3.54297C3.27979 7.47168 3.0918 7.28369 3.0918 7.02051C3.0918 6.77612 3.27979 6.56934 3.54297 6.56934H10.4609C10.7053 6.56934 10.9121 6.77612 10.9121 7.02051Z'
          fill='currentColor'
        />
      </svg>
    </Icon>
  );
};

MinusIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default MinusIcon;
