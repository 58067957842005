import { Stack, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { Tooltip } from '~/components/tool-tip/tool-tip';

export const MaxSpend = ({ amount }) => {
  return (
    <Stack direction='row' gap={1} alignItems='center'>
      <Text>Max. spend ${amount}</Text>
      <Tooltip TooltipText={`Transaction needs to be $${amount} or less to qualify for cashback`} />
    </Stack>
  );
};

MaxSpend.propTypes = {
  amount: PropTypes.number.isRequired,
};
