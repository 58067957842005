export const colors = {
  primary_color: '#00B896',
  primary_scheme: {
    100: '#001A15',
    200: '#004D3E',
    300: '#008068',
    400: '#00997D',
    500: '#00B896',
    600: '#80FFE8',
    700: '#99FFEC',
    800: '#CCFFF6',
  },
  'status-error': '#836b6b',
  landingPageGradient: {
    top_right: '#D5F2BE',
    middle: '#92DBCC',
    bottom_left: '#3FAEBD',
  },

  /*
   * Finfare Theme v1.0 colors
   */
  // very dark grey, presumably for body text
  neutral: {
    primary: '#444444',
  },
  // main brand color; think FinFare green or EC blue
  brand: {
    50: '#E6F8F4',
    100: '#D9F4EF',
    200: '#B1E7DE',
    300: '#01B694',
    400: '#01A485',
    500: '#009275',
    600: '#03896F',
    700: '#0A735F',
    800: '#005243',
    900: '#004035',
    primary: '#01B694',
    secondary: '#0A735F',
    tertiary: '#E6F8F4',
  },
  // all-use greyscale
  secondary: {
    50: '#F2F2F2',
    100: '#EBEEEF',
    200: '#D9DBDF',
    300: '#828B97',
    400: '#747D88',
    500: '#686F79',
    600: '#626871',
    700: '#4E535B',
    800: '#3A3F43',
    900: '#2F3135',
    primary: '#828B97',
    secondary: '#D9DBDF',
    tertiary: '#F2F2F2',
  },
  // white, light backgrounds
  surface: {
    white: '#FFFFFF',
    surface: '#F7FAFC',
  },
  // red, also called danger or error
  red: {
    50: '#FEEFEB',
    100: '#FCCCC2',
    200: '#F9AD9D',
    300: '#F35C3B',
    400: '#DB5335',
    500: '#C24A2E',
    600: '#B6442C',
    700: '#923723',
    800: '#6D291B',
    900: '#562016',
    primary: '#F35C3B',
    secondary: '#F9AD9D',
    tertiary: '#FEEFEB',
  },
  // orange, also called warning
  orange: {
    50: '#FFF6EB',
    100: '#FDF2E2',
    200: '#FBE4C2',
    300: '#F3A93B',
    400: '#DB9835',
    500: '#C18630',
    600: '#B67F2D',
    700: '#926523',
    800: '#6D4C1B',
    900: '#553B15',
    primary: '#F3A93B',
    secondary: '#FBE4C2',
    tertiary: '#FFF6EB',
  },
  // green, also called success
  green: {
    50: '#EAFEEF',
    100: '#DFFDE7',
    200: '#BCFBCE',
    300: '#28F261',
    400: '#23DA57',
    500: '#1FC24F',
    600: '#1DB649',
    700: '#18913A',
    800: '#126D2C',
    900: '#0C5522',
  },
  purple: {
    50: '#F5EAFF',
    100: '#F0E1FD',
    200: '#DFC2FB',
    300: '#973BF3',
    400: '#8835DB',
    500: '#7930C2',
    600: '#712BB6',
    700: '#5B2293',
    800: '#441B6D',
    900: '#351555',
  },
  blue: {
    50: '#E8EEFF',
    100: '#E0E8F9',
    200: '#C1D0F3',
    300: '#3664DA',
    400: '#315AC2',
    500: '#2B50AE',
    600: '#294BA2',
    700: '#203B81',
    800: '#182D62',
    900: '#14234C',
  },
  aqua: {
    50: '#EAFDFF',
    100: '#DFFBFD',
    200: '#BDF7FC',
    300: '#28E6F2',
    400: '#27CFDB',
    500: '#21B8C2',
    600: '#21ACB6',
    700: '#188A91',
    800: '#14666E',
    900: '#0E5155',
  },
  yellow: {
    50: '#FEFDEC',
    100: '#FEFDE2',
    200: '#FDFAC3',
    300: '#F6EF3E',
    400: '#DDD738',
    500: '#C5BF32',
    600: '#B9B32F',
    700: '#948F26',
    800: '#6E6C1C',
    900: '#565416',
  },
};
