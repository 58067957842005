import { Icon } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const CheckIcon = ({ size = 4, color = 'currentColor' }) => {
  return (
    <Icon width='auto' height={size} viewBox='0 0 20 20' fill='none' color={color}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
      >
        <path
          d='M15.8276 5.73499C16.0693 6.00354 16.0693 6.40637 15.8276 6.64807L8.73779 13.7379C8.46924 14.0065 8.06641 14.0065 7.82471 13.7379L4.17236 10.0856C3.90381 9.84387 3.90381 9.44104 4.17236 9.19934C4.41406 8.93079 4.81689 8.93079 5.05859 9.19934L8.25439 12.3951L14.9146 5.73499C15.1562 5.49329 15.5591 5.49329 15.8008 5.73499H15.8276Z'
          fill='currentColor'
        />
      </svg>
      ;
    </Icon>
  );
};

CheckIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default CheckIcon;
