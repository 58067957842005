import { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAccountState } from '~/hooks/account/useAccountState';
import { useCategories } from '~/hooks/useCategories';
import usePublisherName from '~/hooks/usePublisherName';
import { DEFAULT_RADIUS, QUERY_PARAMS, RESTAURANTS } from '~/lib/constants';
import { NoRestaurantsFound } from './no-restaurants-found';
import { Restaurants } from './restaurants';

export function RestaurantsPage() {
  const [isInitialized, setIsInitialized] = useState(false);
  const { publisherName } = usePublisherName();
  const { userZipCode } = useAccountState();
  const { categories, error, isLoaded } = useCategories();

  const navigate = useNavigate();
  const location = useLocation();

  const restaurantCategoryId = categories
    .find((category) => category.category.toLowerCase() === RESTAURANTS.toLowerCase())
    ?.category_id?.toString();

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const currentRadius = params.get(QUERY_PARAMS.RADIUS);
    const currentSearchLocation = params.get(QUERY_PARAMS.SEARCH_LOCATION);

    if (!currentRadius) {
      params.set(QUERY_PARAMS.RADIUS, DEFAULT_RADIUS);
    }

    if (userZipCode && currentSearchLocation === null) {
      params.set(QUERY_PARAMS.SEARCH_LOCATION, userZipCode);
    }

    const newUrl = `${location.pathname}?${params.toString()}`;

    setIsInitialized(true);

    navigate(newUrl, { replace: true });
  }, [location.pathname, location.search, navigate, userZipCode]);

  const noRestaurants = error || (isInitialized && isLoaded && !restaurantCategoryId);

  const pageTitle = (
    <Helmet>
      <title>Restaurants | {publisherName}</title>
    </Helmet>
  );

  if (!isLoaded || !isInitialized) {
    return <Fragment>{pageTitle}</Fragment>;
  }

  if (noRestaurants) {
    return (
      <Fragment>
        {pageTitle}
        <NoRestaurantsFound />
      </Fragment>
    );
  }

  return (
    <Fragment>
      {pageTitle}
      <Restaurants categoryId={restaurantCategoryId} />
    </Fragment>
  );
}
