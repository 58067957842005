import { Icon } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const TicketsPerforatedIcon = ({ size = 4, color = 'currentColor' }) => {
  return (
    <Icon width='auto' height={size} viewBox='0 0 24 17' fill='none' color={color}>
      <svg
        width='24'
        height='17'
        viewBox='0 0 24 17'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M5.86957 2.1084C5.5394 2.1084 5.28261 2.40188 5.28261 2.69536V10.9127C5.28261 11.2429 5.5394 11.4997 5.86957 11.4997H21.1304C21.4239 11.4997 21.7174 11.2429 21.7174 10.9127V9.51872C20.837 8.96845 20.25 7.94128 20.25 6.80405C20.25 5.66682 20.837 4.67633 21.7174 4.12606V2.69536C21.7174 2.40188 21.4239 2.1084 21.1304 2.1084H5.86957ZM3.52174 2.69536C3.52174 1.41139 4.54891 0.347529 5.86957 0.347529H21.1304C22.4144 0.347529 23.4783 1.41139 23.4783 2.69536V4.7497C23.4783 5.07987 23.1848 5.33666 22.8913 5.4834C22.3777 5.70351 22.0109 6.21709 22.0109 6.80405C22.0109 7.42769 22.3777 7.94128 22.8913 8.16139C23.1848 8.30813 23.4783 8.56492 23.4783 8.8584V10.9127C23.4783 12.2334 22.4144 13.2606 21.1304 13.2606H5.86957C4.54891 13.2606 3.52174 12.2334 3.52174 10.9127V2.69536ZM18.1957 3.86927C17.8655 3.86927 17.6087 3.61247 17.6087 3.28231C17.6087 2.98883 17.8655 2.69536 18.1957 2.69536C18.4891 2.69536 18.7826 2.98883 18.7826 3.28231C18.7826 3.61247 18.4891 3.86927 18.1957 3.86927ZM18.1957 6.21709C17.8655 6.21709 17.6087 5.9603 17.6087 5.63014C17.6087 5.33666 17.8655 5.04318 18.1957 5.04318C18.4891 5.04318 18.7826 5.33666 18.7826 5.63014C18.7826 5.9603 18.4891 6.21709 18.1957 6.21709ZM18.7826 7.97796C18.7826 8.30813 18.4891 8.56492 18.1957 8.56492C17.8655 8.56492 17.6087 8.30813 17.6087 7.97796C17.6087 7.68449 17.8655 7.39101 18.1957 7.39101C18.4891 7.39101 18.7826 7.68449 18.7826 7.97796ZM18.1957 10.9127C17.8655 10.9127 17.6087 10.656 17.6087 10.3258C17.6087 10.0323 17.8655 9.73883 18.1957 9.73883C18.4891 9.73883 18.7826 10.0323 18.7826 10.3258C18.7826 10.656 18.4891 10.9127 18.1957 10.9127ZM1.76087 3.57579V12.3801C1.76087 13.8475 2.93478 15.0214 4.40217 15.0214H19.0761C19.553 15.0214 19.9565 15.425 19.9565 15.9019C19.9565 16.4155 19.553 16.7823 19.0761 16.7823H4.40217C1.94429 16.7823 0 14.838 0 12.3801V3.57579C0 3.09889 0.366848 2.69536 0.880435 2.69536C1.35734 2.69536 1.76087 3.09889 1.76087 3.57579Z'
          fill='currentColor'
        />
      </svg>
    </Icon>
  );
};

TicketsPerforatedIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default TicketsPerforatedIcon;
