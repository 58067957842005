import { Icon } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const CalendarMinusIcon = ({ boxSize = 4, color = 'currentColor', ...props }) => {
  return (
    <Icon boxSize={boxSize} viewBox='0 0 14 16' color={color} {...props}>
      <svg
        width='14'
        height='16'
        viewBox='0 0 14 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M4 0.5V2H10V0.5C10 0.25 10.2188 0 10.5 0C10.75 0 11 0.25 11 0.5V2H12C13.0938 2 14 2.90625 14 4V5V6V14C14 15.125 13.0938 16 12 16H2C0.875 16 0 15.125 0 14V6V5V4C0 2.90625 0.875 2 2 2H3V0.5C3 0.25 3.21875 0 3.5 0C3.75 0 4 0.25 4 0.5ZM1 6V14C1 14.5625 1.4375 15 2 15H12C12.5312 15 13 14.5625 13 14V6H1ZM2 3C1.4375 3 1 3.46875 1 4V5H13V4C13 3.46875 12.5312 3 12 3H2ZM9.5 11H4.5C4.21875 11 4 10.7812 4 10.5C4 10.25 4.21875 10 4.5 10H9.5C9.75 10 10 10.25 10 10.5C10 10.7812 9.75 11 9.5 11Z'
          fill={color}
        />
      </svg>
    </Icon>
  );
};

CalendarMinusIcon.propTypes = {
  boxSize: PropTypes.number,
  color: PropTypes.string,
};

export default CalendarMinusIcon;
