import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { OFFER_LOCATION, OFFER_TYPE } from '~/lib/constants';
import { useTranslations } from './useTranslations';

export const BRAND_FILTER_FIELDS = {
  OFFER_TYPES: 'offer_types',
  OFFER_LOCATIONS: 'offer_locations',
};
const BRAND_FILTER_KEYS = Object.values(BRAND_FILTER_FIELDS);
const INITIAL_FILTER_STATE = BRAND_FILTER_KEYS.reduce((acc, keys) => {
  acc[keys] = null;
  return acc;
}, {});

const validateBrandFilter = (filter) => {
  return BRAND_FILTER_KEYS.every((key) => {
    const value = filter[key];
    if (value == null) return true;

    switch (key) {
      case BRAND_FILTER_FIELDS.OFFER_TYPES:
        return value.every((v) => Object.values(OFFER_TYPE).includes(v));
      case BRAND_FILTER_FIELDS.OFFER_LOCATIONS:
        return value.every((v) => Object.values(OFFER_LOCATION).includes(v));
      default:
        return false;
    }
  });
};

export const useBrandFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams(
    new URLSearchParams(window.location.search)
  );
  const [isValid, setIsValid] = useState(true);
  const { brandsTranslation } = useTranslations();

  const brandFilterOptions = useMemo(() => {
    const filters = [
      {
        display: brandsTranslation.filters.ONLINE,
        value: {
          [BRAND_FILTER_FIELDS.OFFER_TYPES]: null,
          [BRAND_FILTER_FIELDS.OFFER_LOCATIONS]: [OFFER_LOCATION.ONLINE_ONLY],
        },
      },
      {
        display: brandsTranslation.filters.IN_STORE,
        value: {
          [BRAND_FILTER_FIELDS.OFFER_TYPES]: [
            OFFER_TYPE.CARD_LINKED,
            OFFER_TYPE.VOUCHER_CODE,
            OFFER_TYPE.AFFILIATE,
          ],
          [BRAND_FILTER_FIELDS.OFFER_LOCATIONS]: [OFFER_LOCATION.IN_STORE_ONLY],
        },
      },
      {
        display: brandsTranslation.filters.PROMO_CODES,
        value: {
          [BRAND_FILTER_FIELDS.OFFER_TYPES]: [OFFER_TYPE.VOUCHER_CODE],
          [BRAND_FILTER_FIELDS.OFFER_LOCATIONS]: null,
        },
      },
      {
        display: brandsTranslation.filters.PAYMENT_LINKED,
        value: {
          [BRAND_FILTER_FIELDS.OFFER_TYPES]: [OFFER_TYPE.CARD_LINKED],
          [BRAND_FILTER_FIELDS.OFFER_LOCATIONS]: null,
        },
      },
      {
        display: brandsTranslation.filters.GIFT_CARD,
        value: {
          [BRAND_FILTER_FIELDS.OFFER_TYPES]: [OFFER_TYPE.GIFT_CARD],
          [BRAND_FILTER_FIELDS.OFFER_LOCATIONS]: null,
        },
      },
    ];

    return filters;
  }, [brandsTranslation]);

  const brandFilter = useMemo(() => {
    const bf = Object.fromEntries(
      BRAND_FILTER_KEYS.map((field) => {
        const values = searchParams.getAll(field); // always returns an array
        switch (field) {
          case BRAND_FILTER_FIELDS.OFFER_TYPES:
          case BRAND_FILTER_FIELDS.OFFER_LOCATIONS:
            // These fields should keep their array structure.
            return [field, values.length > 0 ? values[0].split(',') : null];
          default:
            // For other fields, use the first value or null if none.
            return [field, values.length > 0 ? values[0] : null];
        }
      })
    );
    const valid = validateBrandFilter(bf);
    if (!valid) {
      setIsValid(false);
    }
    return valid ? bf : INITIAL_FILTER_STATE;
  }, [searchParams]);

  const setBrandFilter = useCallback(
    (filter) => {
      const newSearchParams = new URLSearchParams(searchParams);
      // don't put null values in the URL
      Object.entries(filter).forEach(([key, value]) => {
        if (value == null) {
          newSearchParams.delete(key);
        } else {
          newSearchParams.set(key, value);
        }
      });

      setSearchParams(newSearchParams);
    },
    [setSearchParams, searchParams]
  );

  // if the filter is invalid, reset it
  useEffect(() => {
    if (!isValid) {
      const newSearchParams = new URLSearchParams(searchParams);
      BRAND_FILTER_KEYS.forEach((key) => {
        newSearchParams.delete(key);
      });
      setSearchParams(newSearchParams);
      setIsValid(true);
    }
  }, [brandFilter, searchParams, setSearchParams, isValid]);

  return { brandFilter, setBrandFilter, brandFilterOptions };
};
