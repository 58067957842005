import {
  Box,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  useBreakpointValue,
} from '@chakra-ui/react';
import { memo, useEffect, useState } from 'react';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useCategories } from '~/hooks/useCategories';
import { useTranslations } from '~/hooks/useTranslations';
import { BRANDS_SEARCH_ROUTE, QUERY_PARAMS } from '~/lib/constants';

const Search = memo(function Search(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { genericTranslation: t, brandsTranslation: b } = useTranslations();
  const [value, setValue] = useState(searchParams.get(QUERY_PARAMS.SEARCH_TEXT) || '');

  const categoryId = Number(searchParams.get(QUERY_PARAMS.CATEGORY_ID)) || '';

  const { categories } = useCategories();

  const activeCategory = categories.find((c) => c.category_id === categoryId);

  const responsive = useBreakpointValue(
    {
      base: {
        showLeftAddon: false,
        showButtonIcon: true,
      },
      sm: {
        showLeftAddon: false,
        showButtonIcon: true,
      },
      md: {
        showLeftAddon: true,
        showButtonIcon: false,
      },
      lg: {
        showLeftAddon: true,
        showButtonIcon: false,
      },
    },
    { fallback: 'md', ssr: false }
  );

  const placeholder = activeCategory
    ? b.searchForBrandsIn(activeCategory.category)
    : b.searchForBrands;

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newSearchParams = new URLSearchParams(searchParams);
    if (value) {
      newSearchParams.set(QUERY_PARAMS.SEARCH_TEXT, value);
    } else {
      newSearchParams.delete(QUERY_PARAMS.SEARCH_TEXT);
    }

    navigate(
      {
        pathname: BRANDS_SEARCH_ROUTE,
        search: newSearchParams.toString(),
      },
      { replace: true }
    );
  };

  useEffect(() => {
    if (location.pathname !== BRANDS_SEARCH_ROUTE) {
      setValue('');
    }
  }, [location.pathname]);

  return (
    <Box as='form' onSubmit={handleSubmit} {...props}>
      <InputGroup variant='search' height='3rem'>
        {responsive.showLeftAddon && (
          <InputLeftAddon
            sx={{
              backgroundColor: 'white',
              borderTopLeftRadius: 'full',
              borderBottomLeftRadius: 'full',
              borderLeft: responsive.showLeftAddon ? '1px solid' : 'none',
              borderColor: 'blackAlpha.400',
              height: '3rem',
              pr: 0,
            }}
          >
            <img alt='search icon' src='/icons/text-search.svg' />
          </InputLeftAddon>
        )}

        <Input
          name='search'
          placeholder={placeholder}
          onChange={handleChange}
          value={value}
          height='100%'
          borderLeftColor={'blackAlpha.400'}
          borderLeft={
            responsive.showLeftAddon ? '1px solid blackAlpha.400' : '1px solid blackAlpha.400'
          }
          borderLeftWidth={responsive.showLeftAddon ? 0 : '1px'}
          borderTopLeftRadius={responsive.showLeftAddon ? 'none' : 'full'}
          borderBottomLeftRadius={responsive.showLeftAddon ? 'none' : 'full'}
          borderRight='none'
        />
        <InputRightAddon
          as='button'
          type='submit'
          background='primary_color'
          px={6}
          borderTopRightRadius='full'
          borderBottomRightRadius='full'
          fontSize='1rem'
          fontWeight={500}
          height='100%'
          color='white'
        >
          {responsive.showButtonIcon ? (
            <Icon fontSize={'1.25rem'}>
              <FaMagnifyingGlass />
            </Icon>
          ) : (
            t.search
          )}
        </InputRightAddon>
      </InputGroup>
    </Box>
  );
});

export default Search;
