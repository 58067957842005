import { defineStyle, defineStyleConfig } from '@chakra-ui/react';
import { res } from '~/lib/helpers';

const baseStyle = defineStyle(() => ({
  background: 'primary_color',
  colorScheme: 'primary_scheme',
  color: 'white',
  fontSize: '18px',
  borderRadius: '12px',
  flexShrink: 0,
  px: 3,
  py: 6,
  _hover: {
    bg: 'primary_scheme.600',
  },
}));

const search = defineStyle({
  background: 'white',
  colorScheme: 'white',
  color: 'primary_color',
  border: '1px solid',
  borderColor: 'primary_color',
  py: '7px',
});

const outline = defineStyle({
  background: 'white',
  colorScheme: 'white',
  color: 'primary_color',
  border: '1px solid',
  borderColor: 'primary_color',
  _hover: {
    backgroundColor: 'primary_color',
    color: 'secondary_color',
  },
});

const thin = {
  _hover: {
    backgroundColor: 'primary_color',
    color: 'secondary_color',
  },
  py: '7px',
};

const outlineThin = defineStyle({
  background: 'white',
  colorScheme: 'white',
  color: 'primary_color',
  border: '1px solid',
  borderColor: 'primary_color',
  _hover: {
    backgroundColor: 'primary_color',
    color: 'secondary_color',
  },
  py: '7px',
});

const userSign = defineStyle({
  px: [3, 3, 3, 20, 20],
  flexBasis: '100px',
  maxWidth: '100px',
  flexGrow: 0,
  flexShrink: 0,
});

const userSignOutline = defineStyle({
  background: 'white',
  colorScheme: 'white',
  color: 'primary_color',
  border: '1px solid',
  borderColor: 'primary_color',
  _hover: {
    backgroundColor: 'secondary_color',
    color: 'primary_color',
  },
  px: [3, 3, 3, 20, 20],
  maxWidth: '100px',
  flexGrow: 0,
  flexBasis: '100px',
  flexShrink: 0,
});

const LANDING_PAGE_BUTTONS = {
  fontSize: res({ base: 18, md: 24 }, true),
  fontFamily: 'poppins',
  py: '20px',
  px: res({ base: 40, md: 60 }, true),
  height: 'auto',
  borderRadius: '100px',
};

const landingPageOffer = defineStyle({
  background: 'white',
  colorScheme: 'white',
  color: 'black',
  ...LANDING_PAGE_BUTTONS,
  _hover: {
    backgroundColor: 'secondary_color',
  },
});

const landingPageGettingStarted = defineStyle({
  backgroundColor: 'black',
  color: 'white',
  ...LANDING_PAGE_BUTTONS,
});

const categoryCashBack = {
  background: 'secondary_color',
  border: '1px solid',
  borderColor: 'primary_color',
  borderRadius: '7px',
  color: 'primary_color',
  colorScheme: null,
  _hover: {
    background: 'primary_color',
    color: 'white',
  },
  p: '9px',
};

const plain = defineStyle({
  padding: 0,
  margin: 0,
  background: 'none',
  boxShadow: 'none',
  color: 'neutral.primary',
  _hover: {
    background: 'none',
  },
});

export const buttonTheme = defineStyleConfig({
  baseStyle,
  defaultProps: {
    colorScheme: 'primary_scheme',
  },
  variants: {
    categoryCashBack,
    landingPageGettingStarted,
    landingPageOffer,
    link: (props) => ({
      ...props.theme.components.Button.variants.link, // Preserve default 'link' variant styles
      backgroundColor: 'transparent',
      _hover: {
        backgroundColor: 'transparent',
      },
    }),
    outline,
    plain,
    search,
    thin,
    'outline-thin': outlineThin,
    unstyled: (props) => ({
      ...props.theme.components.Button.variants.link, // Preserve default 'link' variant styles
      backgroundColor: 'transparent',
      _hover: {
        backgroundColor: 'secondary.tertiary',
      },
    }),
    userSign,
    userSignOutline,
  },
});
