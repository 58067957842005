import { Tooltip as ChakraTooltip } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { CircleInfoIcon } from '../icons';

export const Tooltip = ({ TooltipText }) => {
  return (
    <ChakraTooltip label={TooltipText} hasArrow bg='secondary.300' borderRadius='md' p={3}>
      <span>
        <CircleInfoIcon boxSize={6} color='#00A336' />
      </span>
    </ChakraTooltip>
  );
};

Tooltip.propTypes = {
  TooltipText: PropTypes.string.isRequired,
};

export default Tooltip;
