import { Icon } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const PartyConfettiIcon = ({ size = 4, color = 'currentColor' }) => {
  return (
    <Icon width='auto' height={size} viewBox='0 0 84 90' fill='none' color={color}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='84'
        height='90'
        viewBox='0 0 84 90'
        fill='none'
      >
        <g clipPath='url(#clip0_16539_10078)'>
          <path
            d='M54.3785 58.6022L49.9457 62.8271L47.5824 65.0795L41.58 70.8014L39.2146 73.0518L35.9935 76.1223L33.2362 78.8341L30.8909 81.139L24.9365 86.9917L22.8031 89.0911C22.7331 89.1616 22.6611 89.2261 22.5871 89.2865C21.2315 90.4128 19.394 90.1287 18.3223 89.0246C17.7405 88.4242 17.3866 87.5841 17.4565 86.5948L17.9284 79.8798L18.1104 77.2707L18.6162 70.0378L18.7542 68.0714L19.6979 51.1034L20.1138 43.6227L20.1838 42.3574L29.3493 46.5823L33.2163 48.3653L37.3731 50.2814L43.1136 53.0919L47.0325 55.0079L54.3805 58.6042L54.3785 58.6022Z'
            fill='#4DC181'
          />
          <path
            d='M49.9457 62.8241L47.5824 65.0766L43.1116 53.0869L47.0305 55.0029L49.9457 62.8241Z'
            fill='#D5EFE0'
          />
          <path
            d='M41.578 70.7992L39.2147 73.0497L29.3494 46.5781L33.2163 48.3612L41.578 70.7992Z'
            fill='#D5EFE0'
          />
          <path
            d='M33.2361 78.8296L30.8908 81.1344L19.6958 51.0989L20.1117 43.6182L33.2361 78.8296Z'
            fill='#D5EFE0'
          />
          <path
            d='M24.9365 86.9872L22.8031 89.0865C22.7331 89.157 22.6611 89.2215 22.5871 89.2819L18.1104 77.2661L18.6162 70.0332L24.9365 86.9892V86.9872Z'
            fill='#D5EFE0'
          />
          <path
            d='M54.8508 57.8871C56.5232 54.2518 50.1608 47.6998 40.6401 43.2528C31.1194 38.8058 22.0456 38.1477 20.3733 41.783C18.7009 45.4183 25.0633 51.9703 34.584 56.4173C44.1047 60.8643 53.1785 61.5224 54.8508 57.8871Z'
            fill='#4DC181'
          />
          <path
            opacity='0.1'
            d='M33.5502 55.9185L18.3223 89.0205C17.7405 88.4202 17.3866 87.58 17.4565 86.5908L17.9284 79.8757L26.1402 51.333C28.2096 52.9428 30.7249 54.5203 33.5522 55.9185H33.5502Z'
            fill='white'
          />
          <path
            d='M36.2535 45.8508C36.0576 46.2719 36.2375 46.7736 36.6574 46.971C37.0753 47.1685 37.5732 46.9872 37.7691 46.5641C38.6149 44.7427 39.5366 44.1303 40.6063 43.4231C41.724 42.6817 42.9917 41.8415 44.0374 39.5871C45.0831 37.3346 44.9112 35.8155 44.7592 34.4737C44.6133 33.1923 44.4873 32.0882 45.3331 30.2649C46.1788 28.4436 47.1006 27.8311 48.1703 27.1239C49.288 26.3825 50.5556 25.5424 51.6014 23.2879C51.7973 22.8668 51.6173 22.3651 51.1975 22.1677C50.7796 21.9703 50.2817 22.1516 50.0858 22.5747C49.24 24.396 48.3182 25.0085 47.2485 25.7156C46.1308 26.4571 44.8632 27.2972 43.8175 29.5517C42.7717 31.8041 42.9437 33.3233 43.0957 34.6651C43.2416 35.9464 43.3676 37.0525 42.5218 38.8738C41.676 40.6952 40.7543 41.3076 39.6846 42.0148C38.5669 42.7562 37.2972 43.5964 36.2535 45.8508Z'
            fill='#C9E8FB'
          />
          <path
            d='M70.0825 46.7678C70.2185 46.3225 69.9725 45.8511 69.5307 45.7141C67.6932 45.1399 65.7457 44.8276 63.7442 44.7873C63.2824 44.7772 62.9005 45.1479 62.8925 45.6133C62.8865 45.9579 63.0844 46.256 63.3743 46.393C63.4763 46.4414 63.5903 46.4696 63.7122 46.4716C65.5577 46.5079 67.3493 46.794 69.0368 47.3218C69.4787 47.4588 69.9466 47.211 70.0825 46.7678Z'
            fill='#FFED00'
          />
          <path
            d='M43.6715 53.2328C43.7535 53.3375 43.8575 53.4161 43.9694 53.4705C44.2494 53.6015 44.5893 53.5733 44.8452 53.3658C44.9052 53.3174 50.9136 48.5324 58.5815 46.965C59.0334 46.8723 59.3273 46.427 59.2353 45.9717C59.1433 45.5164 58.7014 45.2202 58.2496 45.3129C50.1918 46.9609 44.0594 51.8426 43.8035 52.0501C43.4436 52.3403 43.3836 52.8701 43.6715 53.2348V53.2328Z'
            fill='#FBBA00'
          />
          <path
            d='M20.3678 24.392C24.2028 26.8902 27.126 30.825 29.0595 36.0855C29.1395 36.301 29.2974 36.4662 29.4894 36.5569C29.6853 36.6496 29.9153 36.6657 30.1332 36.5831C30.5671 36.4219 30.787 35.9364 30.6271 35.5012C28.5676 29.8922 25.4205 25.6774 21.2756 22.9776C20.8877 22.7258 20.3698 22.8366 20.1199 23.2274C19.87 23.6183 19.9799 24.1401 20.3678 24.392Z'
            fill='#F29FC5'
          />
          <path
            d='M31.2871 40.7683C30.8332 40.8589 30.5393 41.3002 30.6292 41.7575C31.2191 44.7736 31.2751 46.9333 31.2771 46.9696C31.2851 47.3 31.481 47.5841 31.7589 47.7151C31.8709 47.7674 31.9989 47.7956 32.1308 47.7936C32.5927 47.7836 32.9586 47.3987 32.9486 46.9333C32.9486 46.8407 32.8926 44.6124 32.2708 41.4311C32.1808 40.9738 31.7429 40.6776 31.2891 40.7683H31.2871Z'
            fill='#F29FC5'
          />
          <path
            d='M62.6403 35.3377C64.3859 36.1597 66.4713 35.3982 67.2871 33.6393C68.1029 31.8804 67.3471 29.7811 65.6015 28.9571C63.856 28.1351 61.7706 28.8966 60.9548 30.6555C60.139 32.4143 60.8948 34.5157 62.6403 35.3377ZM64.8937 30.4842C65.8035 30.9134 66.1994 32.0094 65.7735 32.9261C65.3476 33.8428 64.2599 34.2417 63.3501 33.8126C62.4404 33.3834 62.0445 32.2874 62.4704 31.3707C62.8963 30.454 63.984 30.0551 64.8937 30.4842Z'
            fill='#83D0F5'
          />
          <path
            d='M35.7778 7.07111C37.5233 7.89312 39.6088 7.13155 40.4245 5.37269C41.2403 3.61384 40.4845 1.51247 38.739 0.690463C36.9935 -0.131547 34.908 0.630021 34.0922 2.38888C33.2765 4.14774 34.0323 6.2491 35.7778 7.07111ZM38.0312 2.21763C38.9409 2.64677 39.3368 3.74278 38.9109 4.65948C38.4851 5.57618 37.3974 5.9751 36.4876 5.54596C35.5778 5.11682 35.182 4.02081 35.6078 3.10411C36.0337 2.18741 37.1214 1.78849 38.0312 2.21763Z'
            fill='#8C7BA3'
          />
          <path
            d='M70.6823 67.0799C72.4279 67.9019 74.5133 67.1403 75.3291 65.3815C76.1448 63.6226 75.3891 61.5213 73.6435 60.6993C71.898 59.8772 69.8126 60.6388 68.9968 62.3977C68.181 64.1565 68.9368 66.2579 70.6823 67.0799ZM72.9357 62.2264C73.8455 62.6556 74.2414 63.7516 73.8155 64.6683C73.3896 65.585 72.3019 65.9839 71.3921 65.5548C70.4824 65.1256 70.0865 64.0296 70.5124 63.1129C70.9383 62.1962 72.026 61.7973 72.9357 62.2264Z'
            fill='#F0836E'
          />
          <path
            d='M21.4836 36.8971C22.2994 35.1383 21.5436 33.0369 19.7981 32.2149C18.0525 31.3929 15.9671 32.1544 15.1513 33.9133C14.3356 35.6722 15.0913 37.7735 16.8369 38.5955C18.5824 39.4175 20.6678 38.656 21.4836 36.8971ZM17.5447 37.0684C16.6349 36.6392 16.239 35.5432 16.6649 34.6265C17.0908 33.7098 18.1785 33.3109 19.0883 33.74C19.998 34.1692 20.3939 35.2652 19.968 36.1819C19.5421 37.0986 18.4544 37.4975 17.5447 37.0684Z'
            fill='#D4524C'
          />
          <path
            d='M59.2333 56.0999L60.6429 54.055C60.8249 53.7911 60.8429 53.4465 60.6889 53.1665C60.535 52.8864 60.237 52.7172 59.9191 52.7333L57.4518 52.8502L55.9582 50.8677C55.7663 50.6118 55.4463 50.4889 55.1344 50.5494C54.8225 50.6098 54.5706 50.8435 54.4866 51.1538L53.8328 53.5553L51.4994 54.3753C51.1995 54.4801 50.9836 54.7501 50.9456 55.0664C50.9056 55.3847 51.0495 55.697 51.3155 55.8743L53.3789 57.2423L53.4309 59.7305C53.4369 60.0508 53.6248 60.3409 53.9128 60.4759C54.2007 60.6109 54.5406 60.5706 54.7885 60.3712L56.718 58.8158L59.0834 59.5351C59.3873 59.6277 59.7192 59.5371 59.9351 59.3034C60.1531 59.0697 60.2191 58.7312 60.1071 58.431L59.2353 56.102L59.2333 56.0999ZM56.778 57.0731C56.5141 56.9925 56.2281 57.0489 56.0142 57.2221L55.0644 57.9877L55.0385 56.7628C55.0325 56.4847 54.8925 56.2289 54.6626 56.0758L53.6468 55.4028L54.7965 54.9999C55.0564 54.9092 55.2544 54.6937 55.3284 54.4277L55.6503 53.2451L56.3861 54.2222C56.552 54.4438 56.816 54.5667 57.0899 54.5546L58.3056 54.4982L57.6118 55.5056C57.4538 55.7333 57.4198 56.0234 57.5158 56.2833L57.9457 57.4297L56.782 57.0751L56.778 57.0731Z'
            fill='#6787B2'
          />
          <path
            d='M31.6227 21.7531L33.6882 23.1211L33.7402 25.6093C33.7462 25.9297 33.9341 26.2198 34.222 26.3548C34.51 26.4898 34.8499 26.4495 35.0978 26.25L37.0273 24.6946L39.3926 25.4139C39.6966 25.5066 40.0285 25.4159 40.2444 25.1822C40.4623 24.9485 40.5283 24.61 40.4164 24.3098L39.5446 21.9808L40.9542 19.9358C41.1362 19.6719 41.1542 19.3274 41.0002 19.0474C40.8462 18.7673 40.5483 18.5981 40.2304 18.6142L37.7631 18.731L36.2695 16.7485C36.0775 16.4927 35.7576 16.3698 35.4457 16.4302C35.1338 16.4907 34.8819 16.7244 34.7979 17.0346L34.1441 19.4362L31.8107 20.2562C31.5108 20.361 31.2948 20.6289 31.2568 20.9472C31.2169 21.2656 31.3608 21.5779 31.6267 21.7551L31.6227 21.7531ZM35.1058 20.8808C35.3657 20.7901 35.5637 20.5745 35.6377 20.3086L35.9596 19.1259L36.6954 20.1031C36.8613 20.3247 37.1253 20.4476 37.4012 20.4355L38.6168 20.3791L37.923 21.3865C37.7651 21.6141 37.7311 21.9042 37.8271 22.1641L38.2569 23.3105L37.0933 22.9559C36.8293 22.8753 36.5434 22.9317 36.3295 23.105L35.3797 23.8706L35.3537 22.6457C35.3477 22.3676 35.2078 22.1118 34.9778 21.9586L33.9621 21.2857L35.1118 20.8828L35.1058 20.8808Z'
            fill='#FBC451'
          />
          <path
            d='M57.218 3.6283L58.6216 4.55709L58.6576 6.24745C58.6616 6.46504 58.7895 6.66249 58.9855 6.75315C59.1814 6.84583 59.4114 6.81762 59.5813 6.68062L60.893 5.62288L62.5005 6.11246C62.7085 6.17492 62.9324 6.11448 63.0804 5.95531C63.2283 5.79615 63.2723 5.56647 63.1963 5.36298L62.6045 3.77941L63.5622 2.39126C63.6862 2.21195 63.6982 1.97824 63.5922 1.78684C63.4882 1.59544 63.2843 1.48261 63.0684 1.49269L61.3908 1.57126L60.3751 0.223408C60.2451 0.0501412 60.0272 -0.0344774 59.8152 0.0078319C59.6033 0.0501412 59.4314 0.209305 59.3754 0.418837L58.9315 2.05077L57.3459 2.60683C57.142 2.67936 56.996 2.86069 56.97 3.07627C56.944 3.29184 57.04 3.5054 57.2219 3.62427L57.218 3.6283Z'
            fill='#FFED00'
          />
          <path
            d='M74.987 29.6668L76.7766 30.8535L76.8225 33.0113C76.8285 33.2893 76.9905 33.5392 77.2404 33.658C77.4904 33.7749 77.7843 33.7406 78.0002 33.5674L79.6718 32.2195L81.7232 32.8441C81.9871 32.9247 82.2731 32.8461 82.463 32.6426C82.651 32.4391 82.7089 32.147 82.611 31.8871L81.8552 29.8683L83.0768 28.0954C83.2348 27.8677 83.2488 27.5695 83.1168 27.3257C82.9829 27.0819 82.7249 26.9369 82.449 26.949L80.3096 27.0497L79.0139 25.3311C78.848 25.1095 78.5701 25.0027 78.3001 25.0551C78.0302 25.1075 77.8123 25.311 77.7383 25.579L77.1724 27.6602L75.149 28.3694C74.8891 28.46 74.7011 28.6937 74.6691 28.9697C74.6351 29.2458 74.7591 29.5178 74.991 29.6689L74.987 29.6668Z'
            fill='#175076'
          />
          <path
            d='M78.5922 54.3391C77.6585 54.4599 76.8047 53.7951 76.6847 52.8542C76.5648 51.9133 77.2246 51.053 78.1583 50.9322C79.0921 50.8113 79.9459 51.4761 80.0658 52.417C80.1858 53.3579 79.526 54.2182 78.5922 54.3391Z'
            fill='#F49C68'
          />
          <path
            d='M50.176 40.05C49.2422 40.1709 48.3884 39.506 48.2685 38.5651C48.1485 37.6243 48.8083 36.764 49.7421 36.6431C50.6758 36.5222 51.5296 37.1871 51.6496 38.128C51.7695 39.0688 51.1097 39.9291 50.176 40.05Z'
            fill='#FBBE3A'
          />
          <path
            d='M9.4909 14.6125C8.55715 14.7334 7.70339 14.0685 7.58342 13.1276C7.46345 12.1868 8.12327 11.3265 9.05702 11.2056C9.99076 11.0847 10.8445 11.7496 10.9645 12.6905C11.0845 13.6313 10.4246 14.4916 9.4909 14.6125Z'
            fill='#DB5335'
          />
          <path
            d='M70.4103 11.9025C69.4766 12.0234 68.6228 11.3586 68.5029 10.4177C68.3829 9.47681 69.0427 8.61652 69.9765 8.49563C70.9102 8.37475 71.764 9.03961 71.8839 9.98049C72.0039 10.9214 71.3441 11.7817 70.4103 11.9025Z'
            fill='#F3A93B'
          />
          <path
            d='M2.71673 38.2756C1.78298 38.3965 0.929215 37.7316 0.809248 36.7907C0.68928 35.8499 1.3491 34.9896 2.28285 34.8687C3.21659 34.7478 4.07036 35.4127 4.19033 36.3535C4.3103 37.2944 3.65048 38.1547 2.71673 38.2756Z'
            fill='#28649E'
          />
        </g>
        <defs>
          <clipPath id='clip0_16539_10078'>
            <rect width='82.4096' height='90' fill='white' transform='translate(0.795166)' />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  );
};

PartyConfettiIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default PartyConfettiIcon;
