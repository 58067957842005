import { Stack, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { ScissorsIcon } from '~/components/icons';
import { BasePromoCodeButton } from './base-promo-code-button';

export const CopyPromoCodeButton = (props) => (
  <BasePromoCodeButton
    {...props}
    promoIcon={<ScissorsIcon />}
    copyIcon={
      <Stack direction='row' align='center' gap={1} px={2}>
        <Text fontSize='sm'>Copy & Shop</Text>
      </Stack>
    }
  />
);

CopyPromoCodeButton.propTypes = {
  code: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isCopied: PropTypes.bool,
};

export default CopyPromoCodeButton;
