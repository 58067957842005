import { Box, Collapse, Stack, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { MinusIcon, PlusIcon } from '~/components/icons';

export const PromoDetails = ({ offer }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { formatted_description, end_date } = offer;

  const formattedDate = end_date
    ? new Date(end_date).toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      })
    : null;

  return (
    <Box>
      <Stack
        direction='row'
        justifyContent='space-between'
        spacing={4}
        align='stretch'
        onClick={() => setIsOpen(!isOpen)}
        cursor='pointer'
      >
        <Box color='gray.500' fontSize='sm'>
          {isOpen ? (
            <Stack direction='row' align='center' gap={0.5}>
              <Text>View Details</Text>
              <MinusIcon size={4} />
            </Stack>
          ) : (
            <Stack direction='row' align='center' gap={0.5}>
              <Text>View Details</Text>
              <PlusIcon size={4} />
            </Stack>
          )}
        </Box>
        {formattedDate && (
          <Text color='gray.500' fontSize='sm'>
            Expires: {formattedDate}
          </Text>
        )}
      </Stack>

      <Collapse in={isOpen} animateOpacity>
        <Box pt={4}>
          <Text color='gray.500' fontSize='sm'>
            {formatted_description}
          </Text>
        </Box>
      </Collapse>
    </Box>
  );
};

PromoDetails.propTypes = {
  offer: PropTypes.object.isRequired,
};
