import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
  useBoolean,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useCallback, useEffect } from 'react';
import { FaPlus } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { useAccountState } from '~/hooks/account/useAccountState';
import { useConfig } from '~/hooks/useConfig/useConfig';
import { useFinance } from '~/hooks/useFinance';
import { useTranslations } from '~/hooks/useTranslations';
import { useUkFinance } from '~/hooks/useUkFinance';
import { ACCOUNT_CARDS_ROUTE, REGIONS } from '~/lib/constants';
import { AddCreditCardButton } from '~/pages/account/cards/add-credit-card-button';
import { UserAndCard } from '~/pages/account/user-and-card';
import CreditCardGroup from './credit-card-group';
import { CreditCardItem } from './credit-card-item';

export function AccountCardsPage() {
  const [loaded, loadedCtrl] = useBoolean(false);
  const { linkCards, institutions, reAuth } = useFinance();
  const { setIsGcModalOpen } = useUkFinance();
  const { isLoggedIn } = useAccountState();
  const navigate = useNavigate();
  const {
    publisher: { region },
  } = useConfig();
  const { financeTranslation: t } = useTranslations();
  const { postModalUrlRef } = useUkFinance();

  useEffect(() => {
    loadedCtrl.on();
  }, [loadedCtrl]);

  const responsive = useBreakpointValue(
    {
      base: {
        nav: 'small',
        stackDir: 'column',
        wrap: 'nowrap',
        pageJustify: 'flex-start',
        align: 'center',
      },
      md: { nav: 'large', stackDir: 'row', wrap: 'wrap', pageJustify: 'stretch' },
      lg: { nav: 'large', stackDir: 'row', wrap: 'wrap', pageJustify: 'stretch' },
    },
    { fallback: 'md' }
  );

  const linkBankAccount = useCallback(() => {
    switch (region) {
      case REGIONS.US: {
        linkCards();
        break;
      }
      case REGIONS.UK: {
        postModalUrlRef.current = ACCOUNT_CARDS_ROUTE;
        setIsGcModalOpen(true);
        break;
      }
      default:
        return;
    }
  }, [region, linkCards, setIsGcModalOpen, postModalUrlRef]);

  if (!isLoggedIn) {
    navigate('/');
    return null;
  }

  if (loaded) {
    return (
      <>
        <Stack height='100%'>
          <Box data-role='page-container' p={['20px', '20px', '40px']} width='100%'>
            <Stack
              direction={responsive.stackDir}
              w='100%'
              justify={responsive.pageJustify}
              spacing={['20px', '40px']}
            >
              <UserAndCard />
              <Card flexShrink={1} flex={1}>
                <CardHeader>
                  <HStack w='100%' justify='space-between'>
                    <Text>{t.myWallet}</Text>
                    <Button size='sm' onClick={linkBankAccount} leftIcon={<FaPlus />}>
                      {t.addCard}
                    </Button>
                  </HStack>
                </CardHeader>
                <CardBody px={'40px'}>
                  <Heading variant='card-subheader'>Cards</Heading>
                  <Box mx={'12px'}>
                    <Flex
                      width='100%'
                      data-role='card-wrapper'
                      direction={responsive.stackDir}
                      wrap={responsive.wrap}
                      alignItems={responsive.align}
                    >
                      {institutions.map((inst) => (
                        <CreditCardGroup key={inst.institution_id} institution={inst} />
                      ))}
                      <AddCreditCardButton onClick={linkBankAccount} />
                    </Flex>
                  </Box>
                  <Heading variant='card-subheader'>Bank Accounts</Heading>
                  <Flex
                    direction={responsive.stackDir}
                    flexWrap={responsive.wrap}
                    alignItems={responsive.align}
                    w='100%'
                    data-role='insts-wrapper'
                  >
                    {institutions?.map((inst) => (
                      <CreditCardItem key={inst.institution_id} inst={inst} reAuth={reAuth} />
                    ))}
                  </Flex>
                </CardBody>
              </Card>
            </Stack>
          </Box>
        </Stack>
      </>
    );
  }
  return null;
}
