import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { EmailVerificationModal, SignInModal, SignUpModal } from '~/components/account';
import LinkBankAccountModal from '~/components/link-bank-account-modal/link-bank-account-modal';
import { ResetPasswordModal } from '~/components/modals/reset-password/reset-password-modal';
import { TermsModal } from '~/components/terms-modal';
import { useAccountAction } from '~/hooks/account/useAccountAction';
import { useAccountState } from '~/hooks/account/useAccountState';
import { useConfig } from '~/hooks/useConfig/useConfig';
import { useMarketingConsent } from '~/hooks/useMarketingConsent';
import { useTerms } from '~/hooks/useTerms';
import { MODALS, REGIONS } from '~/lib/constants';

export const ModalActionContext = createContext(null);
export const ModalStateContext = createContext(null);

export const ModalProvider = ({ children }) => {
  const [modalName, setModalName] = useState(null);

  const config = useConfig();

  const { isLoggedIn } = useAccountState();
  const { setEmailVerificationEmail } = useAccountAction();
  const { acceptTerms, checkNeedsTermAcceptance } = useTerms();
  const { applyMarketingConsent, getMarketingPrompt } = useMarketingConsent();
  const [marketingPrompt, setMarketingPrompt] = useState(null);
  const [needsTermsAcceptance, setNeedsTermsAcceptance] = useState(null);

  const openModal = useCallback((name) => {
    setModalName(name);
  }, []);

  const closeModal = useCallback(() => {
    setModalName(null);
  }, []);

  const handleSignInModalSuccess = useCallback(async () => {
    closeModal();
    const [needsAcceptance, marketingPrompt] = await Promise.all([
      checkNeedsTermAcceptance(),
      getMarketingPrompt(),
    ]);

    if (needsAcceptance || marketingPrompt) {
      openModal(MODALS.TERMS);

      if (needsAcceptance) {
        setNeedsTermsAcceptance(needsAcceptance);
      }

      if (marketingPrompt) {
        setMarketingPrompt(marketingPrompt);
      }
    }
  }, [closeModal, checkNeedsTermAcceptance, openModal, getMarketingPrompt]);

  useEffect(() => {
    if (isLoggedIn) {
      handleSignInModalSuccess();
    }
  }, [handleSignInModalSuccess, isLoggedIn]);

  const modal = useMemo(() => {
    switch (modalName) {
      case MODALS.SIGN_IN:
        return (
          <SignInModal
            onClose={closeModal}
            openSignUp={() => openModal(MODALS.SIGN_UP)}
            onEmailVerification={(email) => {
              setEmailVerificationEmail(email);
              openModal(MODALS.EMAIL_VERIFICATION);
            }}
          />
        );
      case MODALS.SIGN_UP:
        return (
          <SignUpModal
            onClose={closeModal}
            openSignIn={() => {
              openModal(MODALS.SIGN_IN);
            }}
          />
        );

      case MODALS.EMAIL_VERIFICATION:
        return (
          <EmailVerificationModal
            onClose={() => {
              setEmailVerificationEmail('');
              closeModal();
            }}
          />
        );
      case MODALS.LINK_BANK_ACCOUNT:
        if (config?.publisher?.region === REGIONS.UK) {
          return <LinkBankAccountModal />;
        }
        return null;
      case MODALS.TERMS:
        return (
          <TermsModal
            acceptTerms={acceptTerms}
            applyMarketingConsent={applyMarketingConsent ?? undefined}
            needsTermsAcceptance={needsTermsAcceptance}
            marketingPrompt={marketingPrompt ?? undefined}
          />
        );
      case MODALS.RESET_PASSWORD:
        return <ResetPasswordModal onClose={closeModal} />;
      default:
        return null;
    }
  }, [
    config,
    acceptTerms,
    modalName,
    setEmailVerificationEmail,
    openModal,
    closeModal,
    needsTermsAcceptance,
    applyMarketingConsent,
    marketingPrompt,
  ]);

  const modalStateValues = useMemo(
    () => ({
      isModalOpen: (name) => name === modalName,
    }),
    [modalName]
  );

  const modalActionValues = useMemo(() => {
    return {
      openModal,
      closeModal,
    };
  }, [openModal, closeModal]);

  return (
    <ModalActionContext.Provider value={modalActionValues}>
      <ModalStateContext.Provider value={modalStateValues}>
        {children}
        {modal}
      </ModalStateContext.Provider>
    </ModalActionContext.Provider>
  );
};

ModalProvider.propTypes = { children: PropTypes.node.isRequired };
