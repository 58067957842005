import { Stack, Text } from '@chakra-ui/react';
import { CreditCardIcon } from '~/components/icons';

export const CreditCardOnly = () => {
  return (
    <Stack direction='row' gap={3} alignItems='center'>
      <CreditCardIcon />
      <Stack direction='row' gap={1} alignItems='center'>
        <Text>Only credit card purchases qualify</Text>
      </Stack>
    </Stack>
  );
};

CreditCardOnly.propTypes = {};
