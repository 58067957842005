import { Stack, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { CheckIcon } from '~/components/icons';
import { BasePromoCodeButton } from './base-promo-code-button';

export const CopiedPromoCodeButton = (props) => (
  <BasePromoCodeButton
    {...props}
    promoIcon={<></>}
    copyIcon={
      <Stack direction='row' align='center' gap={0.5} px={2}>
        <Text fontSize='sm'>Copied</Text>
        <CheckIcon size={6} />
      </Stack>
    }
    copyIconProps={{
      _hover: {},
    }}
  />
);

CopiedPromoCodeButton.propTypes = {
  code: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isCopied: PropTypes.bool,
};

export default CopiedPromoCodeButton;
