import { defineStyle, defineStyleConfig } from '@chakra-ui/react';
import { res } from '~/lib/helpers';
import { MAJOR_HEAD, MINOR_HEAD } from '~/theme/constants';

/**
 * A NOTE ON FONT FAMILY
 * The default font of text in this site is Roboto.
 * If an element is set to fontFamily: 'poppins', the font family is set to Poppins.
 */

export const headingTheme = defineStyleConfig({
  defaultProps: {
    fontFamily: 'poppins',
    textAlign: 'left',
  },

  baseStyle: defineStyle({
    color: 'primary_color',
    colorScheme: 'primary_scheme',
  }),

  variants: {
    'card-header-no-line': {
      fontFamily: 'poppins',
      fontSize: '20px',
      textAlign: 'left',
      fontWeight: 500,
      pt: '22px',
      pb: '8px',
      px: '34px',
      display: 'block',
      color: 'blackAlpha.800',
    },
    'card-subheader': {
      fontFamily: 'poppins',
      fontSize: '16px',
      lineHeight: '24px',
      textAlign: 'left',
      fontWeight: 500,
      py: '16px',
      borderBottom: '1px solid',
      borderColor: 'blackAlpha.200',
      display: 'block',
      color: 'blackAlpha.800',
      mb: '4px',
    },
    subhead: {
      fontFamily: 'poppins',
      fontSize: '16px',
      lineHeight: '24px',
      textAlign: 'left',
      fontWeight: 600,
      display: 'block',
    },
    'homepage-head': defineStyle({
      fontSize: '28px',
      textAlign: 'left',
      fontFamily: 'poppins',
      fontWeight: 600,
      my: 2,
      pt: ['24px', '26px', '27px'],
      pb: '16px',
      color: 'blackAlpha.800',
    }),
    'landing-gs': {
      ...MINOR_HEAD,
      fontSize: ['24px', '27.5px', '30px'],
      textAlign: 'center',
      color: 'blackAlpha.800',
      mt: ['5px', '5px', '65px'],
      mb: [0, 0, '11px'],
      p: 0,
    },
    'landing-panel': {
      ...MAJOR_HEAD,
      fontSize: ['24px', '36px', '60px'],
      lineHeight: 1.5,
      mt: ['20px', '40px', 'min(20vh,200px)'],
      mb: ['20px', '40px', 'min(10vh, 100px)'],
      color: 'blackAlpha.800',
    },
    'landing-plug': {
      fontSize: res({ base: 20, lg: 30, xl: 45 }, true),
      color: 'blackAlpha.800',
    },
    'landing-question': {
      fontSize: res({ base: 16, md: 18, lg: 20, xl: 30 }, true),
      color: 'blackAlpha.800',
    },
    lp1: {
      color: 'white',
      fontFamily: 'poppins',
      textAlign: ['center', 'center', 'left', 'left'],
      fontSize: ['30px', '30px', '30px', '45px', '65px'],
      lineHeight: '1.1',
      mb: ['16px', '16px', '16px'],
      mt: [0, 0, '50px', '50px', 0],
    },
    'offer-main': {
      fontFamily: 'poppins',
      textAlign: 'center',
      fontSize: '28px',
    },
    'page-head': defineStyle({
      ...MAJOR_HEAD,
      fontFamily: 'poppins',
      color: 'blackAlpha.800',
    }),
    'related-offer': {
      fontFamily: 'poppins',
      fontSize: res({ base: 16, md: 20 }, true),
      textAlign: 'left',
      fontWeight: 600,
      pb: res({ base: 16, md: 24 }, true),
      color: 'blackAlpha.800',
    },
    'rewards-box': {
      fontSize: '14px',
      fontWeight: 500,
      textAlign: 'center',
    },
  },
});
