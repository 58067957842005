import { Icon } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const CircleInfoIcon = ({ boxSize = 4, color = 'currentColor', ...props }) => {
  return (
    <Icon boxSize={boxSize} viewBox='0 0 24 24' color={color} {...props}>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M12.0161 5.29688C9.59912 5.29688 7.40771 6.58594 6.18311 8.64844C4.99072 10.7432 4.99072 13.2891 6.18311 15.3516C7.40771 17.4463 9.59912 18.7031 12.0161 18.7031C14.4009 18.7031 16.5923 17.4463 17.8169 15.3516C19.0093 13.2891 19.0093 10.7432 17.8169 8.64844C16.5923 6.58594 14.4009 5.29688 12.0161 5.29688ZM12.0161 20.25C9.05127 20.25 6.34424 18.7031 4.86182 16.125C3.37939 13.5791 3.37939 10.4531 4.86182 7.875C6.34424 5.3291 9.05127 3.75 12.0161 3.75C14.9487 3.75 17.6558 5.3291 19.1382 7.875C20.6206 10.4531 20.6206 13.5791 19.1382 16.125C17.6558 18.7031 14.9487 20.25 12.0161 20.25ZM10.7271 14.5781H11.5005V12.5156H10.7271C10.2759 12.5156 9.95361 12.1934 9.95361 11.7422C9.95361 11.3232 10.2759 10.9688 10.7271 10.9688H12.2739C12.6929 10.9688 13.0474 11.3232 13.0474 11.7422V14.5781H13.3052C13.7241 14.5781 14.0786 14.9326 14.0786 15.3516C14.0786 15.8027 13.7241 16.125 13.3052 16.125H10.7271C10.2759 16.125 9.95361 15.8027 9.95361 15.3516C9.95361 14.9326 10.2759 14.5781 10.7271 14.5781ZM12.0161 9.9375C11.436 9.9375 10.9849 9.48633 10.9849 8.90625C10.9849 8.3584 11.436 7.875 12.0161 7.875C12.564 7.875 13.0474 8.3584 13.0474 8.90625C13.0474 9.48633 12.564 9.9375 12.0161 9.9375Z'
          fill={color}
        />
      </svg>
    </Icon>
  );
};

CircleInfoIcon.propTypes = {
  boxSize: PropTypes.number,
  color: PropTypes.string,
};

export default CircleInfoIcon;
