import { useToast } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useConfig } from '~/hooks/useConfig/useConfig';
import { useTranslations } from '~/hooks/useTranslations';
import { BRANDS_ROUTE, QUERY_PARAMS } from '~/lib/constants';
import { CustomError, handleError, SilentError } from '~/lib/errors';
import { currentTimeInMS, goUrl } from '~/lib/helpers';
import { TOAST_VARIANT } from '~/theme/default/alert-theme';
import {
  callSignIn,
  clearSerializedAuthData,
  exchangeToken,
  fetchUser,
  refreshAuth,
  updateSerializedData,
  updateUser,
} from './helpers';

const DEFAULT_SSO_TOKEN_VALIDITY_MS = 1000 * 60 * 5; // 5 minutes
const defaultValues = {
  accessToken: null,
  accessTokenExpiration: 0,
  changePasswordAccessToken: null,
  refreshToken: null,
  userEmail: '',
  userId: '',
  userZipCode: '',
  isLoggedIn: false,
  isRefreshLogin: false,
  isAccountStateReady: true,
  emailVerificationEmail: '',
};
const getInitialValue = (key) =>
  localStorage ? localStorage.getItem(key) ?? defaultValues[key] : defaultValues[key];

export const AccountStateContext = createContext();
export const AccountActionContext = createContext();

export const AccountProvider = ({ children }) => {
  const navigate = useNavigate();
  const toast = useToast();
  const {
    account: { sign_out_url },
  } = useConfig();
  const { genericTranslation } = useTranslations();
  const [userEmail, setUserEmail] = useState(() => getInitialValue('userEmail'));
  const [userId, setUserId] = useState(() => getInitialValue('userId'));
  const [accessToken, setAccessToken] = useState(() => getInitialValue('accessToken'));
  const [userZipCode, setUserZipCode] = useState(() => getInitialValue('userZipCode'));
  const [accessTokenExpiration, setAccessTokenExpiration] = useState(() =>
    Number(getInitialValue('accessTokenExpiration') || 0)
  );
  const [changePasswordAccessToken, setChangePasswordAccessToken] = useState(() =>
    getInitialValue('changePasswordAccessToken')
  );
  const [refreshToken, setRefreshToken] = useState(() => getInitialValue('refreshToken'));
  const [isRefreshLogin, setIsRefreshLogin] = useState(() => !!getInitialValue('refreshToken'));
  const isLoggedIn = !!accessToken;
  // TODO: Will likely refactor this piece of state into non-existence
  const isAccountStateReady = true;
  const [emailVerificationEmail, setEmailVerificationEmail] = useState('');

  // create references to token values so exported callbacks don't trigger re-renders
  const accessTokenRef = useRef(accessToken);
  const accessTokenExpirationRef = useRef(accessTokenExpiration);
  const refreshTokenRef = useRef(refreshToken);
  accessTokenRef.current = accessToken;
  accessTokenExpirationRef.current = accessTokenExpiration;
  refreshTokenRef.current = refreshToken;

  let refreshAuthInFlightRef = useRef(false);
  let requestQueueRef = useRef([]);

  const mergeAccountState = useCallback((newAccountState) => {
    setUserEmail((current) =>
      newAccountState.userEmail === undefined ? current : newAccountState.userEmail
    );
    setUserId((current) =>
      newAccountState.userId === undefined ? current : newAccountState.userId
    );
    setUserZipCode((current) =>
      newAccountState.userZipCode === undefined ? current : newAccountState.userZipCode
    );
    setAccessToken((current) =>
      newAccountState.accessToken === undefined ? current : newAccountState.accessToken
    );
    setAccessTokenExpiration((current) =>
      newAccountState.accessTokenExpiration === undefined
        ? current
        : newAccountState.accessTokenExpiration
    );
    setChangePasswordAccessToken((current) =>
      newAccountState.changePasswordAccessToken === undefined
        ? current
        : newAccountState.changePasswordAccessToken
    );
    setRefreshToken((current) =>
      newAccountState.refreshToken === undefined ? current : newAccountState.refreshToken
    );
  }, []);

  const signIn = useCallback(
    async (email, password) => {
      if (!email || !password) {
        throw new Error('Email and password are required');
      }

      try {
        const data = await callSignIn(email, password);

        if (!data) {
          throw new CustomError({
            title: genericTranslation.signIn.signInFailed,
            description: genericTranslation.signIn.unknownError,
          });
        }

        if (!data.access_token) {
          throw new Error('Access token not found');
        }

        const userData = await fetchUser(data.access_token);

        const newAccessTokenExpiration =
          1000 * (Number(data.expires_in) ?? 60 * 5) + currentTimeInMS();
        mergeAccountState({
          userEmail: email,
          userId: userData?.ff_user_id ?? '',
          userZipCode: userData?.primary_zip_code ?? '',
          accessToken: data.access_token,
          accessTokenExpiration: newAccessTokenExpiration,
          changePasswordAccessToken: data.change_password_access_token,
          refreshToken: data.refresh_token,
        });
      } catch (error) {
        console.error('Sign in error', error);
        throw error;
      }
    },
    [genericTranslation, mergeAccountState]
  );

  const signOut = useCallback(
    (customErrorMessage) => {
      setUserEmail(defaultValues.userEmail);
      setUserId(defaultValues.userId);
      setAccessToken(defaultValues.accessToken);
      setChangePasswordAccessToken(defaultValues.changePasswordAccessToken);
      setRefreshToken(defaultValues.refreshToken);
      setAccessTokenExpiration(defaultValues.accessTokenExpiration);
      setIsRefreshLogin(defaultValues.isRefreshLogin);
      setUserZipCode(defaultValues.userZipCode);

      toast({
        title: 'Logged out',
        description: customErrorMessage || 'You have been logged out',
        variant: TOAST_VARIANT.INFO,
        status: TOAST_VARIANT.INFO,
      });

      if (sign_out_url) {
        goUrl(sign_out_url);
      } else {
        navigate(BRANDS_ROUTE);
      }
    },
    [navigate, sign_out_url, toast]
  );

  const updateZipCode = useCallback(
    async (zipCode) => {
      try {
        const resp = await updateUser({ primary_zip_code: zipCode }, accessToken);

        if (!resp) {
          throw new CustomError({
            title: genericTranslation.signIn.zipCodeUpdateFailed,
            description: genericTranslation.signIn.unknownError,
          });
        }

        mergeAccountState({ userZipCode: zipCode });
        return localStorage.setItem('userZipCode', zipCode);
      } catch (error) {
        handleError(toast, error, genericTranslation.signIn.zipCodeUpdateFailed);
      }
    },
    [mergeAccountState, genericTranslation, toast, accessToken]
  );

  const readSsoToken = useCallback(async () => {
    if (typeof window === 'undefined') {
      return;
    }

    const searchParams = new URLSearchParams(window.location.search);
    let oneTimeToken = searchParams.get(QUERY_PARAMS.ONE_TIME_TOKEN);
    if (!oneTimeToken) {
      return;
    }

    // Tokens are base64 encoded, which may contain '+', and URLSearchParams will read it as space ' ',
    // Need to replace ' ' with '+' to obtain the correct token value
    // https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams#preserving_plus_signs
    oneTimeToken = oneTimeToken.replace(/ /g, '+');

    try {
      const data = await exchangeToken(oneTimeToken);

      if (data?.access_token) {
        const accessTokenExpiration =
          (data?.expires_in ? 1000 * Number(data.expires_in) : DEFAULT_SSO_TOKEN_VALIDITY_MS) +
          currentTimeInMS();

        const userData = await fetchUser(data.access_token);

        mergeAccountState({
          userEmail: data?.email,
          userId: userData?.ff_user_id ?? '',
          userZipCode: userData?.primary_zip_code ?? '',
          accessToken: data?.access_token,
          accessTokenExpiration,
          refreshToken: data?.refresh_token,
        });
      } else {
        throw new CustomError({
          title: genericTranslation.signIn.accessTokenFailedTitle,
          description: genericTranslation.signIn.accessTokenFailedDesc,
        });
      }
    } catch (err) {
      handleError(toast, err, genericTranslation.signIn.accessTokenFailedTitle);
    } finally {
      // remove one-time token param but preserve any others
      searchParams.delete(QUERY_PARAMS.ONE_TIME_TOKEN);
      navigate(`${BRANDS_ROUTE}${searchParams.length ? '?' : ''}${searchParams.toString()}`, {
        replace: true,
      });
    }
  }, [navigate, genericTranslation, mergeAccountState, toast]);

  const getValidToken = useCallback(async () => {
    const needsRefresh =
      refreshTokenRef.current &&
      accessTokenRef.current &&
      accessTokenExpirationRef.current &&
      accessTokenExpirationRef.current < currentTimeInMS();

    if (needsRefresh) {
      if (refreshAuthInFlightRef.current) {
        const freshToken = await new Promise((resolve) => {
          requestQueueRef.current.push((token) => {
            resolve(token);
          });
        });

        if (freshToken) {
          return freshToken;
        } else {
          // user has already been signed out and shown a toast message
          // external function state has been reset
          // now I just need to exit quietly without any other toasts, alerts, or noisy errors
          throw new SilentError();
        }
      } else {
        refreshAuthInFlightRef.current = true;

        try {
          const data = await refreshAuth({ accessTokenRef, refreshTokenRef });

          const { access_token, expires_in } = data;
          if (!access_token) {
            throw new Error();
          }

          const newAccessTokenExpiration = (expires_in || 5 * 60) * 1000 + currentTimeInMS();
          mergeAccountState({
            accessToken: access_token,
            accessTokenExpiration: newAccessTokenExpiration,
          });

          // initiate cascade of continuing queued requests
          requestQueueRef.current.forEach((fn) => fn(access_token));

          return access_token;
        } catch (error) {
          // sign user out and show toast message
          signOut('Login has timed out');

          // tell queued requests that the auth token could not be refreshed
          requestQueueRef.current.forEach((fn) => fn());

          // exit gracefully without any other toasts, alerts, or noisy errors
          throw new SilentError();
        } finally {
          // reset external function state
          refreshAuthInFlightRef.current = false;
          requestQueueRef.current = [];
        }
      }
    } else {
      return accessTokenRef.current;
    }
  }, [mergeAccountState, signOut]);

  // update or clear serialized authentication data
  useEffect(() => {
    if (isLoggedIn) {
      updateSerializedData({
        userEmail,
        userId,
        userZipCode,
        accessToken,
        accessTokenExpiration,
        changePasswordAccessToken,
        refreshToken,
      });
    } else {
      clearSerializedAuthData();
    }
  }, [
    userEmail,
    userId,
    userZipCode,
    accessToken,
    accessTokenExpiration,
    changePasswordAccessToken,
    refreshToken,
    isLoggedIn,
  ]);

  const accountActionValues = useMemo(
    () => ({
      getValidToken,
      readSsoToken,
      setEmailVerificationEmail,
      signIn,
      signOut,
      updateZipCode,
    }),
    [getValidToken, readSsoToken, signIn, signOut, updateZipCode]
  );

  const accountStateValues = useMemo(() => {
    return {
      userEmail,
      userId,
      userZipCode,
      accessToken,
      accessTokenExpiration,
      changePasswordAccessToken,
      refreshToken,
      isLoggedIn,
      isRefreshLogin,
      isAccountStateReady,
      emailVerificationEmail,
    };
  }, [
    userEmail,
    userId,
    userZipCode,
    accessToken,
    accessTokenExpiration,
    changePasswordAccessToken,
    refreshToken,
    isLoggedIn,
    isRefreshLogin,
    isAccountStateReady,
    emailVerificationEmail,
  ]);

  return (
    <AccountActionContext.Provider value={accountActionValues}>
      <AccountStateContext.Provider value={accountStateValues}>
        {children}
      </AccountStateContext.Provider>
    </AccountActionContext.Provider>
  );
};

AccountProvider.propTypes = { children: PropTypes.node.isRequired };
