import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import { useConfig } from '~/hooks/useConfig/useConfig';
import { date, formatCurrency, getRegion, status } from '~/lib/helpers';

export default function WithdrawalsCard({ withdrawals }) {
  const config = useConfig();
  const region = getRegion(config);

  return (
    <VStack spacing={4} p={4}>
      {withdrawals.map((withdrawal) => (
        <Box key={withdrawal.id} layerStyle='table-card'>
          <Box layerStyle='table-card-top'>
            <HStack gap={0} alignItems='center'>
              <Box flexBasis='66%'>
                <Text textStyle='table-card-prop-head'>Act. No. Last Four</Text>
                <Text textStyle='table-card-text'>{withdrawal.bank_account_last_four}</Text>
              </Box>
              <Box flexBasis='33%'>
                <VStack w='100%' gap={1} alignItems='flex-end'>
                  <Text textStyle='table-card-prop-head'>Status</Text>
                  <Text textStyle='table-card-text'>{status(withdrawal.status)}</Text>
                </VStack>
              </Box>
            </HStack>
          </Box>
          <Box layerStyle='table-card-bottom'>
            <HStack gap={0} alignItems='center'>
              <Box flexBasis='66%'>
                <VStack gap={1} alignItems='flex-start'>
                  <Text textStyle='table-card-prop-head'>Amount</Text>
                  <Text textStyle='table-card-text'>
                    {formatCurrency(withdrawal.amount, region)}
                  </Text>
                </VStack>
              </Box>
              <Box flexBasis='33%'>
                <VStack gap={1} alignItems='flex-end'>
                  <Text textStyle='table-card-prop-head'>Date</Text>
                  <Text textStyle='table-card-text'>{date(withdrawal.created_date)}</Text>
                </VStack>
              </Box>
            </HStack>
          </Box>
        </Box>
      ))}
    </VStack>
  );
}

WithdrawalsCard.propTypes = {
  withdrawals: PropTypes.array,
};
