import { useCallback, useEffect, useState } from 'react';
import {
  BRANDS_API_URL,
  DEFAULT_PAGE_SIZE,
  DEFAULT_RADIUS,
  QUERY_PARAMS,
  SORT_BY,
} from '~/lib/constants';
import { useFetchUrl } from './useFetchUrl';

const useBrandsApi = ({
  categoryId = '',
  searchText = '',
  page = 0,
  pageSize = DEFAULT_PAGE_SIZE,
  searchLocation = '',
  radius = DEFAULT_RADIUS,
}) => {
  const fetchUrl = useFetchUrl();
  const [brands, setBrands] = useState([]);
  const [hasMoreBrands, setHasMoreBrands] = useState(true);
  const [isBrandsLoading, setIsBrandsLoading] = useState(true);
  const [error, setError] = useState(null);

  // params
  const [paramCategoryId, setParamCategoryId] = useState(categoryId);
  const [paramSearchText, setParamSearchText] = useState(searchText);
  const [paramSearchLocation, setParamSearchLocation] = useState(searchLocation);
  const [paramRadius, setParamRadius] = useState(radius);
  const [paramPage, setParamPage] = useState(page);
  const [paramPageSize, setParamPageSize] = useState(pageSize);

  useEffect(() => {
    setParamCategoryId(categoryId);
    setParamSearchText(searchText);
    setParamPage(page);
    setParamPageSize(pageSize);
    setParamSearchLocation(searchLocation);
    setParamRadius(radius);
  }, [categoryId, page, pageSize, searchText, searchLocation, radius]);

  const fetchBrands = useCallback(
    async (abortController) => {
      // set params
      const params = new URLSearchParams();
      params.set(QUERY_PARAMS.CATEGORY_ID, paramCategoryId);
      params.set(QUERY_PARAMS.SEARCH_TEXT, paramSearchText);
      params.set(QUERY_PARAMS.SEARCH_LOCATION, paramSearchLocation);
      params.set(QUERY_PARAMS.RADIUS, paramRadius);
      params.set(QUERY_PARAMS.PAGE, paramPage);
      params.set(QUERY_PARAMS.SIZE, paramPageSize);
      params.set(QUERY_PARAMS.SORT, SORT_BY.USER_OFFER_RANK);

      const urlWithQueryString = `${BRANDS_API_URL}?${params}`;

      // pre api call
      setIsBrandsLoading(true);
      setError(null);

      try {
        const response = await fetchUrl(urlWithQueryString, {
          signal: abortController?.signal,
        });
        const data = await response.json();

        // post api call
        setBrands(data.content);
        setHasMoreBrands(!data.last);
        setIsBrandsLoading(false);
      } catch (err) {
        if (err.name !== 'AbortError') {
          setError(err);
          setBrands([]);
          setHasMoreBrands(true);
          setIsBrandsLoading(false);
        }
      } 
    },
    [
      fetchUrl,
      paramCategoryId,
      paramPage,
      paramPageSize,
      paramRadius,
      paramSearchLocation,
      paramSearchText,
    ]
  );

  useEffect(() => {
    const abortController = new AbortController();
    fetchBrands(abortController);
    return () => {
      abortController.abort();
    };
  }, [fetchBrands]);

  return { brands, isBrandsLoading, error, hasMoreBrands };
};

export default useBrandsApi;
