import { cloneDeep, isObject, merge } from 'lodash-es';

/* Unique string to represent explicit deletion
   do not change without updating same variable in pub portal, too. */
const DELETION_MARKER = '@@__DELETED__@@';

/**
 * Merges a diff into the original object, handling deletions
 * @param {Object} original - The original object to merge into
 * @param {Object} diff - The diff to merge
 * @returns {Object} A new merged object
 */
export function mergeConfig(original, diff) {
  // If no diff, return original
  if (!diff) return original;

  // Create a deep clone to avoid mutating the original
  let result = cloneDeep(original);

  // Merge the diff, using our custom merge function
  result = merge(result, diff);

  // Recursively remove any properties marked for deletion
  const removeDeletedProperties = (obj) => {
    if (!isObject(obj)) {
      return;
    }

    Object.keys(obj).forEach((key) => {
      if (obj[key] === DELETION_MARKER) {
        delete obj[key];
      } else if (isObject(obj[key])) {
        removeDeletedProperties(obj[key]);
        // Remove empty objects after deletion
        if (Object.keys(obj[key]).length === 0) {
          delete obj[key];
        }
      }
    });
  };

  removeDeletedProperties(result);

  return result;
}
