import { Stack, Text } from '@chakra-ui/react';
import { Tooltip } from '~/components/tool-tip/tool-tip';

export const LinkCardToActivate = () => {
  return (
    <Stack direction='row' gap={1} alignItems='center'>
      <Text>Link card to activate</Text>
      <Tooltip TooltipText='Offer can be activated by linking a card above' />
    </Stack>
  );
};
