import { Stack, Text } from '@chakra-ui/react';
import { Tooltip } from '~/components/tool-tip/tool-tip';

export const ClickToActivate = () => {
  return (
    <Stack direction='row' gap={1} alignItems='center'>
      <Text>Click to activate</Text>
      <Tooltip TooltipText='Offer can be activated by clicking the button above' />
    </Stack>
  );
};
