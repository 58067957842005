import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
import { res } from '~/lib/helpers';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  overlay: {
    bg: 'blackAlpha.200', //change the background
  },
  dialog: {
    borderRadius: '20px',
    minWidth: ['100%', '520px'],
    bg: `white`,
    px: ['20px', '20px', '30px'],
    py: ['20px', '20px', '30px'],
    top: res({ base: '0px', md: 'undefined' }),
  },
  header: {
    fontFamily: 'poppins',
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '32px',
    textAlign: 'center',
    mb: '10px',
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'stretch',
    spacing: 4,
    alignContent: 'stretch',
  },
});

export const modalTheme = defineMultiStyleConfig({
  baseStyle,
});
