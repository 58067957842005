import { Avatar, Card, CardBody, Heading, Text, VStack } from '@chakra-ui/react';
import * as PropTypes from 'prop-types';

export function RewardsCard({ children, amount, icon }) {
  return (
    <Card w='150px' mx={2} my={3}>
      <CardBody p='20px'>
        <VStack h='100%' justify='center'>
          <Avatar icon={icon} backgroundColor='primary_color' width='90px' height='90px' />
          <Text>{amount}</Text>
          <Heading variant='rewards-box'>{children}</Heading>
        </VStack>
      </CardBody>
    </Card>
  );
}

RewardsCard.propTypes = {
  amount: PropTypes.string,
  children: PropTypes.string,
  icon: PropTypes.object,
};
