import { Stack, Text } from '@chakra-ui/react';
import { ZipCodeIcon } from '~/components/icons';

export const ZipCodeRequired = () => {
  return (
    <Stack direction='row' gap={3} alignItems='center'>
      <ZipCodeIcon />
      <Stack direction='row' gap={1} alignItems='center'>
        <Text>Add your zip code to get cashback</Text>
      </Stack>
    </Stack>
  );
};

ZipCodeRequired.propTypes = {};
