import {
  Box,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { IoChevronForward } from 'react-icons/io5';
import { LuLayoutGrid } from 'react-icons/lu';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslations } from '~/hooks/useTranslations';
import { BRANDS_SEARCH_ROUTE, QUERY_PARAMS, RESTAURANTS } from '~/lib/constants';
import { DiningUtensilsIcon } from '../icons';

export const CategoryMenu = memo(function CatMenuBase({ categories }) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { brandsTranslation: t, genericTranslation } = useTranslations();

  if (!Array.isArray(categories)) {
    return null;
  }

  const sortedCategories = categories.sort((category) =>
    category.category === RESTAURANTS ? -1 : 1
  );

  const topCategories = sortedCategories.slice(0, 9);
  const restCategories = sortedCategories.slice(9);

  const handleClear = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete(QUERY_PARAMS.CATEGORY_ID);

    navigate({
      pathname: BRANDS_SEARCH_ROUTE,
      search: newSearchParams.toString(),
    });
  };

  const handleNavigate = (categoryId) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(QUERY_PARAMS.CATEGORY_ID, categoryId);

    navigate({
      pathname: BRANDS_SEARCH_ROUTE,
      search: newSearchParams.toString(),
    }),
      {
        replace: true,
      };
  };

  const handleCustomCategoryNavigate = (slug) => {
    navigate({
      pathname: `brands/category/${slug}`,
    }),
      {
        replace: true,
      };
  };

  const activeCategory = categories.find(
    (c) => c.category_id === Number(searchParams.get(QUERY_PARAMS.CATEGORY_ID))
  );

  const renderMenuItem = (c) => {
    if (c.category === RESTAURANTS) {
      return (
        <MenuItem
          onClick={() => {
            handleCustomCategoryNavigate('restaurants');
          }}
          value={c.category_id}
          key={c.category_id}
        >
          <Box>
            <Text color='orange.600' fontWeight='600'>
              {c.category}
            </Text>
            <Stack
              alignItems='center'
              bgColor='orange.600'
              borderRadius='100px'
              color='white'
              flexDirection='row'
              mb='3'
              px='4'
              py='1'
              gap='1'
              width='fit-content'
            >
              <DiningUtensilsIcon color='white' size='14px' />
              <Text fontSize='sm' fontWeight='500'>
                {genericTranslation.fivePercentCashbackOnDining}
              </Text>
            </Stack>
          </Box>
        </MenuItem>
      );
    }

    return (
      <MenuItem
        onClick={() => {
          handleNavigate(c.category_id);
        }}
        value={c.category_id}
        key={c.category_id}
      >
        {c.category}
      </MenuItem>
    );
  };

  return (
    <Menu>
      <MenuButton width='max-content' backgroundColor='transparent' color='primary_scheme.500'>
        <HStack>
          <Box w='20px' h='20px' color='primary_scheme.500'>
            <LuLayoutGrid size={20} />
          </Box>
          <Text textStyle='header-text'>
            {activeCategory ? activeCategory.category : t.categories.root}
          </Text>
          <Box w={'16px'} h={'17px'} color='primary_scheme.500'>
            <IoChevronForward style={{ transform: 'rotate(90deg) translateX(1px)' }} />
          </Box>
        </HStack>
      </MenuButton>
      <MenuList data-testid='global_header_category-menu'>
        {categories ? (
          <>
            <MenuItem onClick={handleClear} value={'all'} key={'all'}>
              {t.categories.all}
            </MenuItem>
            {topCategories.map(renderMenuItem)}
            <MenuDivider />
            <Menu offset={[9, 0]} placement='left-end'>
              <MenuButton pl={3}>{t.categories.more}</MenuButton>
              <MenuList data-testid='global_header_more-menu'>
                {restCategories.map(renderMenuItem)}
              </MenuList>
            </Menu>
          </>
        ) : null}
      </MenuList>
    </Menu>
  );
});

CategoryMenu.propTypes = {
  categories: PropTypes.array.isRequired,
};
