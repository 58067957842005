import { ModalBody, Text, VStack } from '@chakra-ui/react';
import { useTheme } from '@emotion/react';
import { GoCheckCircleFill } from 'react-icons/go';
import { useTranslations } from '~/hooks/useTranslations';

export const ResetPasswordSuccess = () => {
  const {
    genericTranslation: {
      resetPassword: { success },
    },
  } = useTranslations();
  const theme = useTheme();

  return (
    <>
      <ModalBody>
        <VStack width='100%'>
          <GoCheckCircleFill size={40} color={theme.colors.brand.primary} />
          <Text fontSize='xl' fontWeight='medium' fontFamily='poppins' textAlign='center'>
            {success}
          </Text>
        </VStack>
      </ModalBody>
    </>
  );
};
