import { Box, Button, Center, Image, Text, VStack } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useConfig } from '~/hooks/useConfig/useConfig';
import { useFinance } from '~/hooks/useFinance';
import useIsNarrowScreen from '~/hooks/useIsNarrowScreen';
import { useTranslations } from '~/hooks/useTranslations';
import { useUkFinance } from '~/hooks/useUkFinance';
import { ACCOUNT_CARDS_ROUTE, ACCOUNT_ROUTE, REGIONS } from '~/lib/constants';
import imgUrl, { neutralizeEvent } from '~/lib/helpers';

export function CardInfo() {
  const narrow = useIsNarrowScreen();
  const navigate = useNavigate();
  const {
    publisher: { region },
  } = useConfig();
  const { cardImageUrl, cards, linkCards, statusText } = useFinance();
  const { setIsGcModalOpen, postModalUrlRef } = useUkFinance();
  const { financeTranslation: t } = useTranslations();

  const linkBankAccount = useCallback(() => {
    switch (region) {
      case REGIONS.US:
        linkCards();
        break;
      case REGIONS.UK:
        postModalUrlRef.current = ACCOUNT_ROUTE;
        setIsGcModalOpen(true);
        break;
      default:
        return;
    }
  }, [region, linkCards, setIsGcModalOpen, postModalUrlRef]);

  const handleManageCards = useCallback(
    (e) => {
      neutralizeEvent(e);
      navigate(ACCOUNT_CARDS_ROUTE);
    },
    [navigate]
  );

  let buttons;
  if (narrow) {
    buttons =
      cards?.length > 0 ? (
        <>
          <Button onClick={handleManageCards}>
            <Image alt='icon' width='24px' height='24px' src='/icons/my-wallet.png' mr='20px' />
            {t.myWallet}
          </Button>
          <Button variant='secondary' onClick={linkBankAccount}>
            {t.linkMoreCards}
          </Button>
        </>
      ) : (
        <Button onClick={linkBankAccount}>{t.linkACard}</Button>
      );
  } else {
    buttons = (
      <Button onClick={linkBankAccount}>{cards?.length > 0 ? t.linkMoreCards : t.linkACard}</Button>
    );
  }

  return (
    <VStack
      width='100%'
      px='1.5rem'
      py='1.5rem'
      height='100%'
      spacing='1rem'
      justify='flex-end'
      flexBasis={narrow ? undefined : '50%'}
      alignItems='stretch'
      data-role='user-Vstack'
    >
      <Center>
        <Box
          position='relative'
          backgroundImage={imgUrl(cardImageUrl)}
          backgroundPosition='top center'
          backgroundSize='contain'
          width={{ base: '128px', md: '175px', lg: '175px' }}
          height={{ base: '80px', md: '175px', lg: '110px' }}
        />
      </Center>
      <Text>{statusText}</Text>
      {buttons}
    </VStack>
  );
}
