import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import Confetti from 'react-confetti';
import { Countdown } from '~/components/countdown/countdown';
import { PartyConfettiIcon } from '~/components/icons';
import { PromoCodeButton } from '~/components/promo-code-button';

export const PromoCodeModal = ({ isOpen, onClose, code, trackingUrl }) => {
  const [width, setWidth] = useState(0);

  const handlePromoCodeClick = () => {
    if (code && typeof navigator !== 'undefined') {
      navigator.clipboard.writeText(code);
    }
    if (trackingUrl && typeof window !== 'undefined') {
      window.open(trackingUrl, '_blank');
    }
  };

  const setRef = useCallback((node) => {
    if (node) {
      setWidth(node.clientWidth);
    }
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent ref={setRef}>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={6} align='center'>
            <Confetti
              width={width}
              numberOfPieces={150}
              recycle={false}
              confettiSource={{ x: width / 2, y: 0 }}
              initialVelocityY={15}
              spread={90}
            />
            <PartyConfettiIcon size={28} />
            <Stack>
              <Text fontSize='xl' fontWeight='bold' textAlign='center'>
                Promo Code Copied!
              </Text>
              <Text fontSize='md' color='gray.500' textAlign='center'>
                Your promo code has been successfully copied. You will be redirected to the
                merchant&apos;s website shortly to complete your purchase.
              </Text>
              <Stack
                direction='row'
                spacing={1}
                justify='center'
                align='center'
                fontSize='md'
                color='gray.500'
              >
                <Text>Redirecting in</Text>
                <Countdown seconds={3} onComplete={handlePromoCodeClick} />
              </Stack>
            </Stack>
            <Box>
              <PromoCodeButton code={code} onClick={handlePromoCodeClick} variant='copied' />
            </Box>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

PromoCodeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  code: PropTypes.string.isRequired,
  trackingUrl: PropTypes.string,
};
