import { res } from '~/lib/helpers';
import {
  BRAND_BANNER_HEIGHT,
  BRAND_IMAGE_RADIUS,
  BRAND_PADDING,
  BRAND_PAGE_BANNER_PX,
  BRAND_PAGE_LOGO_HEIGHT,
  BRAND_PAGE_LOGO_PX,
  CAROUSEL,
  IMAGE_COVER,
  STORE_LOGO_SIZE_PX,
} from '~/theme/constants';

export const layerStyles = {
  'bank-details-box': {
    width: ['310px', '360px'],
    borderRadius: '20px',
    backgroundColor: '#F7FAFC',
    borderColor: 'secondary.secondary',
    borderWidth: '1px',
  },
  brand: {
    mx: [0, 0.5, 1],
    my: [0, 0.5, 1],
    position: 'relative',
    minWidth: ['100%', '169px', '223px', '223px', '223px'],
  },
  'brand-image': {
    ...IMAGE_COVER,
    width: '100%',
    height: BRAND_BANNER_HEIGHT,
  },
  'brand-page-banner': {
    w: '100%',
    h: BRAND_PAGE_BANNER_PX,
    ...IMAGE_COVER,
    position: 'relative',
    borderTopLeftRadius: res({ base: 0, lg: 20 }, true),
    borderTopRightRadius: res({ base: 0, lg: 20 }, true),
  },

  'brand-page-logo': {
    ...IMAGE_COVER,
    w: BRAND_PAGE_LOGO_PX,
    h: BRAND_PAGE_LOGO_PX,
    border: '3px solid white',
    shadow: 'md',
  },
  'brand-page-logo-wrapper': {
    position: 'relative',
    w: '100%',
    h: '1px',
    overflow: 'show',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    mb: `${BRAND_PAGE_LOGO_HEIGHT / 2 + 13}px`, // 10px padding, 3px border
  },
  'brand-text': {
    p: BRAND_PADDING,
    borderBottomLeftRadius: BRAND_IMAGE_RADIUS,
    borderBottomRightRadius: BRAND_IMAGE_RADIUS,
    border: '1px solid',
    borderColor: 'blackAlpha.300',
  },
  'btn-carousel': {
    mb: ['20px', '20px', '61px', '61px'],
    mt: ['16px', '16px', '34px', '34px'],
  },
  carousel: {
    ...IMAGE_COVER,
    position: 'relative',
    backgroundColor: 'transparent',
    height: ['183px', '183px', '329px', '329px'],
    overflow: 'hidden',
    width: '100%',
  },
  'carousel-background': {
    ...IMAGE_COVER,
    ...CAROUSEL,
  },
  'carousel-next-arrow': {
    position: 'absolute',
    right: '10px',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  'carousel-prev': {
    ...IMAGE_COVER,
    ...CAROUSEL,
  },
  'carousel-prev-arrow': {
    position: 'absolute',
    left: '10px',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  categories: {
    px: 2,
    py: 1,
    display: 'block',
    borderBottomWidth: '1px',
    borderColor: 'blackAlpha.300',
    borderBottomStyle: 'solid',
    mb: 3,
  },
  'category-details': {
    ml: ['24px', '24px', '101px', '101px'],
    mt: ['20px', '20px', '61px', '61px'],
  },
  'contact-body': {
    px: ['40px', '40px', '80px', '80px'],
  },
  'contact-card': {
    width: ['350px', '350px', '660px', '660px'],
  },
  'contact-header-container': {
    border: 'none',
    mb: '24px',
    mt: ['24px', '24px', '60px', '60px'],
  },
  'contact-post-submit-card': {
    width: ['350px', '350px', '660px', '660px'],
  },
  'disabled-button': {
    color: 'blackAlpha.600',
    backgroundColor: 'blackAlpha.300',
  },
  footer: {
    alignItems: ['flex-end', 'flex-end', 'flex-end', 'center'],
    backgroundColor: 'secondary.800',
    color: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    px: ['20px', '20px', '40px', '100px'],
    py: '30px',
    w: '100%',
  },
  'header-container': {
    w: '100vw',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    py: ['10px', '10px', '20px', '20px'],
    px: ['20px', '20px', '40px', '40px'],
    backgroundColor: 'secondary.tertiary',
  },
  'input-row': {
    maxWidth: '900px',
    my: [2, 2, 3],
  },
  'input-row-field': {
    px: [1, 1, 2],
    py: [1, 1, 1],
    flex: 1,
    fontSize: 'lg',
    position: 'relative',
    width: '100%',
  },
  'input-row-field-small': {
    px: [4, 4, 6],
    py: [1, 1, 1],
    flex: 1,
    fontSize: 'md',
    position: 'relative',
    width: '100%',
  },
  'input-row-label': {
    width: ['200px', '200px', '250px'],
    px: [1, 1, 2],
    py: [1, 1, 1],
    flexBasis: ['200px', '200px', '250px'],
    flex: 0,
    fontSize: 'lg',
  },
  'input-row-label-small': {
    mt: [2, 2, 3],
    px: [4, 4, 6],
    py: [1, 1, 1],
    display: 'block',
    textAlign: 'center',
    flexBasis: ['200px', '200px', '250px'],
    flex: 0,
    fontSize: 'md',
  },
  'landing-image': {
    height: 'auto',
    mt: ['40px', '100px'],
    width: 'auto',
  },
  'landing-brand-row-grid': {
    mb: ['40px', '40px', '60px', '95px'],
    pt: '155px',
    width: '100%',
  },

  'landing-getting-started-button': {
    mt: ['50px', '50px', '50px', '100px'],
    mb: ['50px', '50px', '50px', '170px'],
  },
  'landing-intro-text': {
    flex: 1,
    textAlign: ['center', 'center', 'left', 'left'],
    flexBasis: ['100%', 'min(600px, 50vw)'],
    flexShrink: 1,
    ml: ['40px', '40px', '100px', '100px', '235px'],
    mr: ['40px', '40px', 0],
    mt: ['40px', '40px', '80px', '80px', '180px'],
  },
  'landing-page-one-image-box': {
    width: res({ base: 170, lg: 200, xl: 280 }, true),
    height: res({ base: 170, lg: 200, xl: 280 }, true),
    flex: 0,
    flexBasis: res({ base: 170, lg: 200, xl: 280 }, true),
    flexShrink: 0,
    borderRadius: '20px',
    boxShadow: 'xl',
    backgroundSize: '220%',
    backgroundPosition: '30% 30%',
    backgroundRepeat: 'no-repeat',
  },
  'landing-panel': {
    w: '100%',
  },
  'landing-plug': {
    mx: res({ base: 20, md: 150, lg: 200 }, true),
    mt: res({ base: 50, md: 150, lg: 200 }, true),
    maxWidth: '1500px',
  },
  'landing-plug-image': {
    height: ['155px', '155px', '155px', '234px'],
    backgroundSize: 'cover',
    backgroundPositionX: 'center',
    backgroundPositionY: 'center',
    width: '100%',
  },
  'landing-plug-text': {
    py: 0,
  },
  'landing-q-head': {
    my: res({ base: 18, md: 24, lxl: 30 }, true),
  },
  'landing-questions': {
    mx: res({ base: 20, md: 150 }, true),
    mb: res({ base: 50, md: 150 }, true),
  },
  'landing-stat': {
    py: ['24px', '24px', '24px', '100px'],
    fontSize: ['16px', '16px', '16px', '30px'],
    textAlign: 'center',
    flexDirection: 'column',
  },
  'logo-icon': {
    ...IMAGE_COVER, // should be IMAGE_CONTAIN - when live, if logo is not square
    height: '100%',
    width: '100%',
    backgroundColor: 'white',
  },
  'lp-button': {
    backdropFilter: 'blur(15px)',
    backgroundColor: 'blackAlpha.500',
    border: '1px solid white',
    borderRadius: '1.5em',
    position: 'absolute',
    px: 8,
    py: 2,
  },
  offer: {
    ...IMAGE_COVER,
    borderWidth: 1,
    borderColor: 'blackAlpha.300',
    mx: 1,
    my: 0.5,
    px: 3,
    py: 1,
  },
  'preview-container': {
    align: 'flex-start',
    border: '1px solid',
    borderColor: 'primary_color',
    borderRadius: '10px',
    height: '58px',
    padding: '16px',
    width: '100%',
  },
  'preview-image-box': {
    height: '300px',
    padding: '20px',
    width: '300px',
  },
  'sign-in-field': {
    p: 7,
  },
  'stacked-field': {
    my: 3,
  },
  store: {
    position: 'relative',
  },
  'store-logo': {
    ...IMAGE_COVER,
    backgroundColor: 'white',
    w: STORE_LOGO_SIZE_PX,
    h: STORE_LOGO_SIZE_PX,
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'secondary.secondary',
    mx: '10%',
    overflow: 'hidden',
  },
  'table-v-head': {
    py: '17px',
  },

  'table-cell': {
    py: '12px',
    m: 0,
  },
  'table-card': {
    w: '100%',
    border: '1px solid',
    borderColor: 'blackAlpha.200',
    borderRadius: '12px',
  },

  'table-card-bottom': {
    m: '20px',
  },
  'table-card-top': {
    m: '20px',
    pb: '12px',
    borderBottom: '1px solid',
    borderBottomColor: 'blackAlpha.200',
  },
  'upload-file-box': {
    border: '1px dashed',
    borderColor: 'primary_color',
    borderRadius: '10px',
    height: '56px',
    width: '100%',
  },
  'upload-icon-box': {
    border: '1px solid #D9DBDF',
    borderRadius: '10px',
    height: '60px',
    padding: '18px',
    width: ['100%', '100%', '60px', '60px'],
  },
};
