import * as PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useState } from 'react';
import { useFetchUrl } from '~/hooks/useFetchUrl';
import { CATEGORIES_API_URL } from '~/lib/constants';

export const CategoriesContext = createContext({ categories: [], error: null, isLoaded: false });

export const CategoriesProvider = ({ children }) => {
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const fetchUrl = useFetchUrl();

  const fetchCategories = useCallback(async () => {
    try {
      const response = await fetchUrl(CATEGORIES_API_URL);
      if (!response.ok) {
        throw new Error(`Failed to fetch categories: ${response.status}`);
      }
      const data = await response.json();
      setCategories(data);
      setError(null);
      setIsLoaded(true);
    } catch (err) {
      setError(err.message);
      setCategories([]);
      setIsLoaded(true);
    }
  }, [fetchUrl]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  return (
    <CategoriesContext.Provider value={{ categories, error, isLoaded }}>
      {children}
    </CategoriesContext.Provider>
  );
};

CategoriesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
