import PropTypes from 'prop-types';
import { CopiedPromoCodeButton } from './copied-promo-code-button';
import { CopyPromoCodeButton } from './copy-promo-code-button';

export const PromoCodeButton = (props) => {
  if (props.variant === 'copied') {
    return <CopiedPromoCodeButton {...props} />;
  }

  // default
  return <CopyPromoCodeButton {...props} />;
};

PromoCodeButton.propTypes = {
  code: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isCopied: PropTypes.bool,
  variant: PropTypes.oneOf(['copied', 'copy']),
};

export default PromoCodeButton;
