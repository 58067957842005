import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  tabsAnatomy.keys
);

// define the base component styles
const baseStyle = definePartsStyle({
  // define the part you're going to style
  tab: {
    _selected: { borderBottom: '2px solid', color: 'primary_color' },
  },
  tabs: {
    p: 0,
    m: 0,
    h: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
  },
  tabpanel: {
    p: 0,
    m: 0,
    h: '100%',
    overflow: 'hidden',
    flex: 1,
  },
  tabpanels: {
    p: 0,
    m: 0,
    h: '100%',
    overflow: 'hidden',
  },
});

// export the component theme
export const tabsTheme = defineMultiStyleConfig({
  baseStyle,
});
