import { Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import { useConfig } from '~/hooks/useConfig/useConfig';
import { date, formatCurrency, getRegion, status } from '~/lib/helpers';
export default function WithdrawalsTable({ withdrawals }) {
  const config = useConfig();
  const region = getRegion(config);

  return (
    <TableContainer>
      <Table variant='simple'>
        <Thead>
          <Tr>
            <Th layerStyle='table-v-head'>
              <Text textStyle='table-v-head'>#</Text>
            </Th>
            <Th layerStyle='table-v-head'>
              <Text textStyle='table-v-head'>Type</Text>
            </Th>
            <Th layerStyle='table-v-head'>
              <Text textStyle='table-v-head'>Date</Text>
            </Th>
            <Th layerStyle='table-v-head'>
              <Text textStyle='table-v-head'>Amount</Text>
            </Th>
            <Th layerStyle='table-v-head'>
              <Text textStyle='table-v-head'>Act. No. Last Four</Text>
            </Th>
            <Th layerStyle='table-v-head'>
              <Text textStyle='table-v-head'>Status</Text>
            </Th>
          </Tr>
        </Thead>
        <Tbody id='table-container'>
          {withdrawals?.map((withdrawal, i) => (
            <Tr key={withdrawal.id}>
              <Td>{i + 1}</Td>
              <Td layerStyle='table-cell'>{withdrawal.withdrawal_type}</Td>
              <Td layerStyle='table-cell' textStyle='table-cell'>
                {date(withdrawal.created_date)}
              </Td>
              <Td layerStyle='table-cell' textStyle='table-cell'>
                {formatCurrency(withdrawal.amount, region)}
              </Td>
              <Td layerStyle='table-cell' textStyle='table-cell'>
                {withdrawal.bank_account_last_four}
              </Td>
              <Td layerStyle='table-cell' textStyle='table-cell'>
                {status(withdrawal.status)}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

WithdrawalsTable.propTypes = {
  withdrawals: PropTypes.array,
};
