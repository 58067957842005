import * as Yup from 'yup';

export const wlConfigV1Schema = Yup.object().shape({
  account: Yup.object()
    .shape({
      sign_in_url: Yup.string(),
      sign_out_url: Yup.string(),
      sign_up_url: Yup.string(),
      sso: Yup.boolean().required(),
    })
    .required("'account' object is missing from config"),
  feature_flag: Yup.object(),
  name: Yup.string().required(),
  publisher: Yup.object()
    .shape({
      publisher_banner: Yup.object().shape({
        bgColor: Yup.string(),
        redirect_url: Yup.string(),
        text: Yup.string(),
      }),
      publisher_home_name: Yup.string().required(),
      publisher_home_url: Yup.string().required(),
      publisher_id: Yup.string().required(),
      publisher_name: Yup.string().required(),
      region: Yup.string().required(),
      stores_we_love: Yup.array()
        .of(
          Yup.object().shape({
            brand_uid: Yup.string().required(),
            name: Yup.string().required(),
          })
        )
        .required(),
    })
    .required("'publisher' object is missing from config"),
  theme: Yup.object()
    .shape({
      colors: Yup.object().shape({
        landingPageGradient: Yup.object().shape({
          bottom_left: Yup.string().required(),
          middle: Yup.string().required(),
          top_right: Yup.string().required(),
        }),
        primary_color: Yup.string().required(),
        secondary_color: Yup.string().required(),
      }),
      images: Yup.object()
        .shape({
          favicon: Yup.string().required(),
          logo_img_sq_url: Yup.string().required(),
          logo_img_url: Yup.string().required().required(),
        })
        .required(),
    })
    .required("'theme' object is missing from config"),
  translations: Yup.object().shape({
    account: Yup.object(),
    brands: Yup.object(),
    finance: Yup.object(),
    generic: Yup.object(),
    giftCard: Yup.object(),
    landing: Yup.object(),
    rewards: Yup.object(),
  }),
  version: Yup.string()
    .oneOf(
      ['1.0'],
      'Provided config is wrong version. You are validating against config version 1.0'
    )
    .required(),
});
