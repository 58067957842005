import { OFFER_TYPE, REGIONS, REWARD_TYPE } from '~/lib/constants';
import { CustomError } from '~/lib/errors';
import { getCurrencySymbol } from '~/lib/helpers';
import { useTranslations } from './useTranslations';

export const useTranslationHelpers = () => {
  const { brandsTranslation } = useTranslations();

  /**
   *
   * @param {Object} object
   * @param {number} object.offerAmount numeric value of offer
   * @param {string} object.region region of publisher
   * @param {string} object.rewardType offer reward type
   * @returns {string}
   * @description Returns the complete offer amount including numeric value, symbol ($, %, £, etc.), and suffix (i.e. "cashback" or "off")
   */
  const offerAmountText = ({ offerAmount, region, rewardType }) => {
    // base number
    let result = [REWARD_TYPE.CASHBACK_PERCENTAGE, REWARD_TYPE.DISCOUNT_PERCENTAGE].includes(
      rewardType
    )
      ? String(offerAmount)
      : offerAmount?.toFixed(2);

    // currency of percentage
    if (rewardType === REWARD_TYPE.CASHBACK_DOLLAR || rewardType === REWARD_TYPE.DISCOUNT_DOLLAR) {
      if ([REGIONS.UK, REGIONS.US].includes(region)) {
        result = `${getCurrencySymbol(region)}${result}`;
      } else {
        // stubbed out for when we support euros, etc.
        result = result.concat(getCurrencySymbol(region));
      }
    } else if (rewardType === REWARD_TYPE.VOUCHER_CODE) {
      result = '';
    } else {
      result = `${result}%`;
    }

    // offer suffix
    switch (rewardType) {
      case REWARD_TYPE.CASHBACK_DOLLAR:
        result = result.concat(brandsTranslation.rewardTypeSymbol.CASHBACK_DOLLAR);
        break;

      case REWARD_TYPE.CASHBACK_PERCENTAGE:
        result = result.concat(brandsTranslation.rewardTypeSymbol.CASHBACK_PERCENTAGE);
        break;

      case REWARD_TYPE.DISCOUNT_DOLLAR:
        result = result.concat(brandsTranslation.rewardTypeSymbol.DISCOUNT_DOLLAR);
        break;

      case REWARD_TYPE.DISCOUNT_PERCENTAGE:
        result = result.concat(brandsTranslation.rewardTypeSymbol.DISCOUNT_PERCENTAGE);
        break;

      default:
        break;
    }

    return result;
  };

  const actionCopy = ({ offerType, body = false, brandsTranslation }) => {
    switch (offerType) {
      case OFFER_TYPE.CARD_LINKED:
        if (body) {
          return brandsTranslation.offerType.CARD_LINKED.automaticCashback[0];
        }
        return brandsTranslation.offerType.CARD_LINKED.automaticCashback[1];

      case OFFER_TYPE.AFFILIATE:
        if (body) {
          return brandsTranslation.offerType.AFFILIATE.automaticCashback[0];
        }
        return brandsTranslation.offerType.AFFILIATE.automaticCashback[1];

      case OFFER_TYPE.GIFT_CARD:
        if (body) {
          return brandsTranslation.offerType.GIFT_CARD.automaticCashback[0];
        }
        return brandsTranslation.offerType.GIFT_CARD.automaticCashback[1];

      case OFFER_TYPE.VOUCHER_CODE:
        if (body) {
          return brandsTranslation.offerType.VOUCHER_CODE.automaticCashback[0];
        }
        return brandsTranslation.offerType.VOUCHER_CODE.automaticCashback[1];

      default:
        throw new CustomError({
          title: 'Unrecognized offer type received',
        });
    }
  };

  return { offerAmountText, actionCopy };
};
