import { Box, Text, VStack } from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useConfig } from '~/hooks/useConfig/useConfig';
import { useTranslationHelpers } from '~/hooks/useTranslationHelpers';
import { OFFER_TYPE } from '~/lib/constants';
import imgUrl, { getBrandPath } from '~/lib/helpers';
import { BuyPrompt } from './buy-prompt';

export function StoreWeLove({ brand }) {
  const {
    publisher: { region },
  } = useConfig();
  const { offerAmountText } = useTranslationHelpers();
  const [hovering, setHovering] = useState(false);

  if (!brand?.brand_uid) {
    return null;
  }

  const isOnlyVoucherCode = brand?.offers?.every(
    (offer) => offer.offer_type === OFFER_TYPE.VOUCHER_CODE
  );

  return (
    <Link to={getBrandPath(brand)}>
      <Box
        key={brand.brand_uid}
        layerStyle='store'
        data-role='store'
        id={brand.brand_uid}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        <VStack justifyContent='center'>
          <Box layerStyle='store-logo' backgroundImage={imgUrl(brand.logo_img_url)}></Box>
          <Text textStyle='store-offer'>
            {isOnlyVoucherCode
              ? 'Promo code discount'
              : offerAmountText({
                  offerAmount: brand?.max_customer_offer,
                  region,
                  rewardType: brand?.reward_type,
                })}
          </Text>
          {hovering && <BuyPrompt />}
        </VStack>
      </Box>
    </Link>
  );
}

StoreWeLove.propTypes = {
  brand: PropTypes.object,
  prompt: PropTypes.string,
};
