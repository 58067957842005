export const formLabelTheme = {
  baseStyle: {
    fontFamily: 'poppins',
    fontWeight: 300,
    fontSize: 'sm',
    color: 'secondary.primary',
    whiteSpace: 'nowrap',
    textAlign: 'left',
  },
};
