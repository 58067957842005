import { Icon } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const CopyIcon = ({ size = 4, color = 'currentColor' }) => {
  return (
    <Icon width='auto' height={size} viewBox='0 0 20 21' fill='none' color={color}>
      <svg
        width='20'
        height='21'
        viewBox='0 0 20 21'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M14.2969 12.0195C14.5117 12.0195 14.7266 11.8315 14.7266 11.5898V6.11133L12.9004 4.28516H9.14062C8.89893 4.28516 8.71094 4.5 8.71094 4.71484V11.5898C8.71094 11.8315 8.89893 12.0195 9.14062 12.0195H14.2969ZM9.14062 13.3086C8.17383 13.3086 7.42188 12.5566 7.42188 11.5898V4.71484C7.42188 3.7749 8.17383 2.99609 9.14062 2.99609H12.9004C13.2227 2.99609 13.5718 3.15723 13.8135 3.39893L15.6128 5.19824C15.8545 5.43994 16.0156 5.78906 16.0156 6.11133V11.5898C16.0156 12.5566 15.2368 13.3086 14.2969 13.3086H9.14062ZM5.70312 6.43359H6.5625V7.72266H5.70312C5.46143 7.72266 5.27344 7.9375 5.27344 8.15234V15.0273C5.27344 15.269 5.46143 15.457 5.70312 15.457H10.8594C11.0742 15.457 11.2891 15.269 11.2891 15.0273V14.168H12.5781V15.0273C12.5781 15.9941 11.7993 16.7461 10.8594 16.7461H5.70312C4.73633 16.7461 3.98438 15.9941 3.98438 15.0273V8.15234C3.98438 7.2124 4.73633 6.43359 5.70312 6.43359Z'
          fill='currentColor'
        />
      </svg>
    </Icon>
  );
};

CopyIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default CopyIcon;
