import { Box, HStack, IconButton, useBreakpointValue, useDisclosure } from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import { lazy, Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { FaBars } from 'react-icons/fa6';
import { IoClose } from 'react-icons/io5';
import { CategoryMenu } from '~/components/header/category-menu';
import { HeaderAccountButtons } from '~/components/header/header-account-buttons';
import Logo from '~/components/logo';
import Search from '~/components/search';
import { useCategories } from '~/hooks/useCategories';
import { useConfig } from '~/hooks/useConfig/useConfig';
import { useScrollDirection } from '~/hooks/useScrollDirection';
import { SupportHeader } from './support-header';

const LazyHamburgerMenu = lazy(() => import('~/components/menu/hamburger-menu'));

export function Header({ setSettingsModalIsOpen }) {
  const supportHeaderRef = useRef(null);
  const {
    isOpen: isHamburgerMenuOpen,
    onClose: closeHamburgerMenu,
    onToggle: toggleHamburgerMenu,
  } = useDisclosure();
  const { categories } = useCategories();
  const direction = useScrollDirection(50);
  const { hide_support_header } = useConfig();

  const responsive = useBreakpointValue(
    {
      base: {
        showCategory: false,
        showSearch: false,
        showHamburger: true,
        square: true,
        searchWidth: '26rem',
      },
      sm: {
        showCategory: false,
        showSearch: false,
        showHamburger: true,
        square: true,
        searchWidth: '26rem',
      },
      md: {
        showCategory: false,
        showSearch: true,
        showHamburger: true,
        square: false,
        searchWidth: '28rem',
      },
      lg: {
        showCategory: true,
        showSearch: true,
        showHamburger: false,
        square: false,
        searchWidth: '36rem',
      },
    },
    { fallback: 'md', ssr: false }
  );

  // get support header height
  const [supportHeaderHeight, setSupportHeaderHeight] = useState(0);
  useEffect(() => {
    if (supportHeaderRef.current && !hide_support_header) {
      const resizeObserver = new ResizeObserver(() => {
        if (supportHeaderRef.current) {
          setSupportHeaderHeight((p) =>
            p === supportHeaderRef.current.clientHeight ? p : supportHeaderRef.current.clientHeight
          );
        }
      });
      resizeObserver.observe(supportHeaderRef.current);
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [hide_support_header]);

  const isSupportHeaderHidden =
    (responsive.showHamburger && isHamburgerMenuOpen) || Boolean(hide_support_header);

  // freeze page so user cannot scroll the page while menu is open
  useEffect(() => {
    if (isHamburgerMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isHamburgerMenuOpen]);

  const search = useMemo(() => {
    if (responsive.showSearch) {
      return <Search width='100%' maxWidth={responsive.searchWidth} />;
    }

    if (responsive.square) {
      return null;
    }
  }, [responsive.square, responsive.showSearch, responsive.searchWidth]);

  return (
    <Box
      as='header'
      transform={{
        base: isSupportHeaderHidden
          ? `translateY(-${supportHeaderHeight}px)`
          : direction === 'down'
            ? 'translateY(-100%)'
            : 'translateY(0)',
        lg: 'translateY(0)',
      }}
      transition='transform 0.3s'
      position='sticky'
      top={0}
      width='100%'
      zIndex='banner'
    >
      {!hide_support_header && <SupportHeader ref={supportHeaderRef} />}
      <HStack
        bg='secondary.tertiary'
        justifyContent='space-between'
        gap={7}
        px={[4, 5, 10, 16]}
        height={{ base: 14, md: 20 }}
      >
        <HStack data-testid='header_left' width='100%' gap={7}>
          <Logo />
          {search}
        </HStack>
        <HStack gap={14}>
          {Boolean(responsive.showCategory && categories) && (
            <CategoryMenu categories={categories} />
          )}

          {responsive.showHamburger ? (
            <>
              <IconButton onClick={toggleHamburgerMenu} variant='ghost' color='neutral.primary'>
                {isHamburgerMenuOpen ? <IoClose size={24} /> : <FaBars size={24} />}
              </IconButton>
              {isHamburgerMenuOpen && (
                <Suspense>
                  <LazyHamburgerMenu
                    isHamburgerMenuOpen={isHamburgerMenuOpen}
                    closeHamburgerMenu={closeHamburgerMenu}
                    toggleHamburgerMenu={toggleHamburgerMenu}
                    setSettingsModalIsOpen={setSettingsModalIsOpen}
                  />
                </Suspense>
              )}
            </>
          ) : (
            <HeaderAccountButtons setSettingsModalIsOpen={setSettingsModalIsOpen} />
          )}
        </HStack>
      </HStack>
    </Box>
  );
}

Header.propTypes = {
  setSettingsModalIsOpen: PropTypes.func.isRequired,
};
