import { Stack, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { Tooltip } from '~/components/tool-tip/tool-tip';

export const LimitedRedemptions = ({ limitedRedemptionsCriteria }) => {
  const { limit, period, periodSpan } = limitedRedemptionsCriteria;
  const limitText = limit === 1 ? 'once' : `${limit} times`;
  const periodText = `${periodSpan} ${period.toLowerCase()}${periodSpan > 1 ? 's' : ''}`;

  return (
    <Stack direction='row' gap={3} alignItems='center'>
      <Stack direction='row' gap={1} alignItems='center'>
        <Text>
          Offer can only be redeemed {limitText} every {periodText}
        </Text>
        <Tooltip TooltipText='Offer can be redeemed a limited number of times within a specific time period' />
      </Stack>
    </Stack>
  );
};

LimitedRedemptions.propTypes = {
  limitedRedemptionsCriteria: PropTypes.object.isRequired,
};
