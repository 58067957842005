import { Stack, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { CalendarMinusIcon } from '~/components/icons';
import { Tooltip } from '~/components/tool-tip/tool-tip';

export const ExcludedDates = ({ excludedDatesCriteria }) => {
  const { value: dates } = excludedDatesCriteria;
  if (!dates?.length) {
    return null;
  }

  return (
    <Stack direction='row' gap={3} alignItems='center'>
      <CalendarMinusIcon />
      <Stack direction='row' gap={1} alignItems='center'>
        <Text>Excluded Dates</Text>
        <Tooltip TooltipText={`Offer is not valid on ${dates.join(', ')}`} />
      </Stack>
    </Stack>
  );
};

ExcludedDates.propTypes = {
  excludedDatesCriteria: PropTypes.shape({
    value: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};
