import {
  Box,
  Button,
  CircularProgress,
  Heading,
  SimpleGrid,
  Spinner,
  Stack,
  useBreakpointValue,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PageContainer } from '~/components/page-container';
import { ZipCodePill } from '~/components/zipcode-pill/zipcode-pill';
import useBrandsApi from '~/hooks/useBrandsApi';
import { useTranslations } from '~/hooks/useTranslations';
import { DEFAULT_PAGE_SIZE, DEFAULT_RADIUS, QUERY_PARAMS } from '~/lib/constants';
import { Brand } from '~/pages/brands/brand';
import { NoRestaurantsFound } from './no-restaurants-found';

export function Restaurants({ categoryId }) {
  const [pageSize, setPageSize] = useState(100);
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const zipCode = searchParams.get(QUERY_PARAMS.SEARCH_LOCATION) ?? '';

  const radiusParam = Number(searchParams.get('radius'));
  const radius = isNaN(radiusParam) ? DEFAULT_RADIUS : radiusParam;

  const { genericTranslation: t } = useTranslations();

  const { brands, isBrandsLoading, hasMoreBrands } = useBrandsApi({
    categoryId,
    searchLocation: zipCode,
    radius,
    pageSize,
  });

  const responsive = useBreakpointValue(
    {
      base: { columns: 1 },
      sm: { columns: 2 },
      md: { columns: 3 },
      lg: { columns: 4 },
      xl: { columns: 4 },
    },
    {
      fallback: 'md',
      ssr: false,
    }
  );

  const handleNavigate = (zipCode, radius) => {
    const newQueryParams = new URLSearchParams(searchParams);
    newQueryParams.set(QUERY_PARAMS.SEARCH_LOCATION, zipCode);
    newQueryParams.set(QUERY_PARAMS.RADIUS, radius);
    navigate(`?${newQueryParams.toString()}`);
  };

  const onZipCodeChange = (zipCode) => {
    handleNavigate(zipCode, radius);
  };

  const onZipCodeClear = () => {
    handleNavigate('', DEFAULT_RADIUS);
  };

  const onRadiusChange = (radius) => {
    handleNavigate(zipCode, radius);
  };

  const onLoadMoreBrands = () => {
    setPageSize((prev) => prev + DEFAULT_PAGE_SIZE);
  };

  const brandItems = Array.from(brands.values());
  const isLoading = isBrandsLoading;
  const noBrandsFound = !isBrandsLoading && brands && !brandItems.length;

  if (noBrandsFound) {
    return (
      <PageContainer>
        <NoRestaurantsFound />
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <Stack alignItems='center' direction={{ base: 'column', md: 'row' }} w='100%' mb='7'>
        <Box>
          <Heading color='neutral.primary' fontSize='2xl'>
            {t.categories.restaurants.restaurants_near_you}
          </Heading>
        </Box>
        <ZipCodePill
          zipCode={zipCode}
          radius={radius}
          onChange={onZipCodeChange}
          onRadiusChange={onRadiusChange}
          onClear={onZipCodeClear}
        />
      </Stack>
      {isLoading ? (
        <Box>
          <CircularProgress size={8} isIndeterminate color='brand.primary' />
        </Box>
      ) : (
        <SimpleGrid columns={responsive.columns} spacing={5} width='100%'>
          {brandItems.map((brand) => (
            <Brand key={brand.brand_uid} brand={brand} />
          ))}
        </SimpleGrid>
      )}
      {hasMoreBrands && !isLoading && (
        <Button isDisabled={isLoading} onClick={onLoadMoreBrands} alignSelf='center' marginTop={16}>
          {isBrandsLoading ? <Spinner color='white' /> : t.loadMore}
        </Button>
      )}
    </PageContainer>
  );
}

Restaurants.propTypes = {
  categoryId: PropTypes.string.isRequired,
};