import { Icon } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const ScissorsIcon = ({ size = 4, color = 'currentColor' }) => {
  return (
    <Icon width='auto' height={size} viewBox='0 0 24 24' fill='none' color={color}>
      <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
        <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round'></g>
        <g id='SVGRepo_iconCarrier'>
          <path
            d='M21 18L14.9339 13.8837M11.7895 11.75L7 8.5M21 5.5L7 15M8 6.5C8 7.88071 6.88071 9 5.5 9C4.11929 9 3 7.88071 3 6.5C3 5.11929 4.11929 4 5.5 4C6.88071 4 8 5.11929 8 6.5ZM8 17C8 15.6193 6.88071 14.5 5.5 14.5C4.11929 14.5 3 15.6193 3 17C3 18.3807 4.11929 19.5 5.5 19.5C6.88071 19.5 8 18.3807 8 17Z'
            stroke='currentColor'
            strokeWidth='1.6320000000000001'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
      </svg>
    </Icon>
  );
};

ScissorsIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default ScissorsIcon;
