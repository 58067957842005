import { Box, Stack } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { ClickToActivate } from './click-to-activate';
import { CreditCardOnly } from './credit-card-only';
import { EndDate } from './end-date';
import { ExcludedDates } from './excluded-dates';
import { LimitedRedemptions } from './limited-redemptions';
import { LinkCardToActivate } from './link-card-to-activate';
import { MaxSpend } from './max-spend';
import { MinSpend } from './min-spend';
import { NewCustomerOnly } from './new-customer-only';
import { PaymentChannel } from './payment-channel';
import { RefundPeriod } from './refund-period';
import { StartDate } from './start-date';
import { ZipCodeRequired } from './zip-code-required';

const VISIBLE_SORTED_CRITERIA = ['ZIPCODE', 'ACCOUNT_TYPE', 'NEW_CUSTOMER_ONLY'];

const getCriteriaComponent = (criterion) => {
  switch (criterion.criteria_type) {
    case 'START_DATE':
      return <StartDate date={criterion.value} />;
    case 'END_DATE':
      return <EndDate date={criterion.value} />;
    case 'MAX_SPEND':
      return <MaxSpend amount={criterion.value} />;
    case 'MIN_SPEND':
      return <MinSpend amount={criterion.value} />;
    case 'PAYMENT_CHANNEL':
      return <PaymentChannel paymentChannel={criterion.value} />;
    case 'NEW_CUSTOMER_ONLY':
      return <NewCustomerOnly />;
    case 'CLICK_TO_ACTIVATE':
      return <ClickToActivate />;
    case 'LINK_CARD_TO_ACTIVATE':
      return <LinkCardToActivate />;
    case 'REFUND_PERIOD':
      return <RefundPeriod refundPeriod={criterion.value} />;
    case 'EXCLUDED_DATES':
      return <ExcludedDates excludedDatesCriteria={criterion} />;
    case 'LIMITED_REDEMPTIONS':
      return <LimitedRedemptions limitedRedemptionsCriteria={criterion.value} />;
    case 'ACCOUNT_TYPE':
      if (criterion.value.includes('credit card')) {
        return <CreditCardOnly />;
      }
      return null;
    case 'ZIPCODE':
      return <ZipCodeRequired />;
    default:
      return null;
  }
};

export const OfferCriteria = ({ offerCriteria = [] }) => {

  const visibleCriterias = offerCriteria
    .filter((criterion) => VISIBLE_SORTED_CRITERIA.includes(criterion.criteria_type))
    .sort(
      (a, b) =>
        VISIBLE_SORTED_CRITERIA.indexOf(a.criteria_type) -
        VISIBLE_SORTED_CRITERIA.indexOf(b.criteria_type)
    );

  if (visibleCriterias.length === 0) {
    return null;
  }

  return (
    <Stack
      direction='row'
      flexWrap='wrap'
      gap={4}
      px='40px'
      py='24px'
      bg='#eeeef233'
      w='100%'
      borderRadius='xl'
      divider={<Box borderColor='gray.100' fontSize='24px' />}
    >
      {visibleCriterias.map((criterion) => (
        <Box key={criterion.criteria_type} whiteSpace='nowrap'>
          {getCriteriaComponent(criterion)}
        </Box>
      ))}
    </Stack>
  );
};

OfferCriteria.propTypes = {
  offerCriteria: PropTypes.array.isRequired,
};
