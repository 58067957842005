import { Box, VStack } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { OFFER_TYPE } from '~/lib/constants';
import { PromoCard } from './components/promo-card';
import { PromoHeader } from './components/promo-header';

export const PromoCodesBlock = ({ brand }) => {
  const { offers } = brand;
  const promoOffers = offers.filter((offer) => offer.offer_type === OFFER_TYPE.VOUCHER_CODE);
  return (
    <Box p={6} bg='white' borderRadius='xl' boxShadow='0px 2px 12px 0px #0000001A' w='full'>
      <Box mb={6}>
        <PromoHeader />
      </Box>
      <VStack spacing={4}>
        {promoOffers.map((offer) => (
          <PromoCard key={offer.offer_uid} offer={offer} />
        ))}
      </VStack>
    </Box>
  );
};

PromoCodesBlock.propTypes = {
  brand: PropTypes.object.isRequired,
};

export default PromoCodesBlock;
