import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const solidGradientTheme = defineStyle({
  position: 'relative',
  bg: 'primary_color',
  color: 'secondary_color',
  borderRadius: '0',
  px: 8,
  py: 4,
  _focus: { boxShadow: 'none' },
  _active: { bg: 'primary_color' },
  zIndex: '2',
  _before: {
    content: '""',
    position: 'absolute',
    bgGradient: 'linear(to-r, #FCE03A, #F78566, #CC99FF, #88E3F2)',
    height: '5px',
    width: '100%',
    bottom: '-5px',
    left: '5px',
    zIndex: '1',
    borderRadius: '0',
    transition: 'all 0.3s ease',
  },
  _after: {
    content: '""',
    position: 'absolute',
    bgGradient: 'linear(to-r, #88E3F2, #88E3F2)',
    height: '100%',
    width: '5px',
    top: '5px',
    right: '-5px',
    zIndex: '1',
    borderRadius: '0',
    transition: 'all 0.3s ease',
  },
});

const outLineGradientTheme = defineStyle({
  border: '0.5px solid',
  borderColor: 'primary_color',
  bg: 'secondary_color',
  color: 'primary_color',
  position: 'relative',
  borderRadius: '0',
  px: 8,
  py: 4,
  _focus: { boxShadow: 'none' },
  _active: { bg: 'black' },
  zIndex: '2',
  _before: {
    content: '""',
    position: 'absolute',
    bgGradient: 'linear(to-r, #FCE03A, #F78566, #CC99FF, #88E3F2)',
    height: '5px',
    width: '100%',
    bottom: '-5px',
    left: '5px',
    zIndex: '1',
    borderRadius: '0',
    transition: 'all 0.3s ease',
  },
  _after: {
    content: '""',
    position: 'absolute',
    bgGradient: 'linear(to-r, #88E3F2, #88E3F2)',
    height: '100%',
    width: '5px',
    top: '0',
    right: '-5px',
    zIndex: '1',
    borderRadius: '0',
    transition: 'all 0.3s ease',
  },
});

const baseStyle = defineStyle(() => ({
  ...solidGradientTheme,
  fontSize: '18px',
  borderRadius: '0',
  flexShrink: 0,
  px: 3,
  py: 6,
}));

const thin = {
  backgroundColor: 'primary_color',
  borderRadius: '0',
  color: 'secondary_color',
};

const outlineThin = defineStyle({
  borderRadius: '0',
});
const userSign = defineStyle({
  ...solidGradientTheme,
  px: [3, 3, 3, 20, 20],
  flexBasis: '100px',
  maxWidth: '100px',
  flexGrow: 0,
  flexShrink: 0,
});

const userSignOutline = defineStyle({
  ...outLineGradientTheme,
  _hover: {
    backgroundColor: 'primary_color',
    color: 'secondary_color',
  },
  px: [3, 3, 3, 20, 20],
  maxWidth: '100px',
  flexGrow: 0,
  flexBasis: '100px',
  flexShrink: 0,
});

const disabled = defineStyle({
  borderRadius: '0',
});

const LANDING_PAGE_BUTTONS = {
  borderRadius: '0',
};

const landingPageOffer = defineStyle({
  ...outLineGradientTheme,
  ...LANDING_PAGE_BUTTONS,
});

const landingPageGettingStarted = defineStyle({
  ...solidGradientTheme,
  ...LANDING_PAGE_BUTTONS,
});

const categoryCashBack = {
  ...outLineGradientTheme,
};

const outline = defineStyle({
  borderRadius: '0',
});

export const buttonTheme = defineStyleConfig({
  defaultProps: {
    colorScheme: 'primary_scheme',
  },
  variants: {
    baseStyle,
    disabled,
    categoryCashBack,
    landingPageGettingStarted,
    landingPageOffer,
    outline,
    'outline-thin': outlineThin,
    thin,
    userSign,
    userSignOutline,
  },
});
