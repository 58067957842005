import { Stack, Text } from '@chakra-ui/react';
import { UserCrownIcon } from '~/components/icons';

export const NewCustomerOnly = () => {
  return (
    <Stack direction='row' gap={3} alignItems='center'>
      <UserCrownIcon />
      <Stack direction='row' gap={1} alignItems='center'>
        <Text>You must be a new customer in order to qualify</Text>
      </Stack>
    </Stack>
  );
};
