import { Stack, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const EndDate = ({ date }) => {
  return (
    <Stack direction='row' gap={1} alignItems='center'>
      <Text>Ends on {date}</Text>
    </Stack>
  );
};

EndDate.propTypes = {
  date: PropTypes.string.isRequired,
};
