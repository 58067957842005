import { Stack, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { Tooltip } from '~/components/tool-tip/tool-tip';

export const RefundPeriod = ({ refundPeriod }) => {
  return (
    <Stack direction='row' gap={1} alignItems='center'>
      <Text>Refund period {refundPeriod} days</Text>
      <Tooltip TooltipText='Offer can be refunded within the specified period' />
    </Stack>
  );
};

RefundPeriod.propTypes = {
  refundPeriod: PropTypes.string.isRequired,
};
