import {
  Box,
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import { useConfig } from '~/hooks/useConfig/useConfig';
import { useTranslations } from '~/hooks/useTranslations';
import { date, formatCurrency, getRegion, status } from '~/lib/helpers';

export function TransactionsTable({ showMore, onShowMore, transactions }) {
  const {
    accountTranslation: { transaction: t },
  } = useTranslations();
  const config = useConfig();
  const region = getRegion(config);
  return (
    <>
      <TableContainer>
        <Table variant='simple'>
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th layerStyle='table-v-head'>
                <Text textStyle='table-v-head'>{t.date}</Text>
              </Th>
              <Th layerStyle='table-v-head'>
                <Text textStyle='table-v-head'>{t.offer}</Text>
              </Th>
              <Th layerStyle='table-v-head'>
                <Text textStyle='table-v-head'>{t.cardNumber}</Text>
              </Th>
              <Th layerStyle='table-v-head'>
                <Text textStyle='table-v-head'>{t.amount}</Text>
              </Th>
              <Th layerStyle='table-v-head'>
                <Text textStyle='table-v-head'>{t.cashback}</Text>
              </Th>
              <Th layerStyle='table-v-head'>
                <Text textStyle='table-v-head'>{t.status}</Text>
              </Th>
            </Tr>
          </Thead>
          <Tbody id='trans-container'>
            {transactions?.map((t, i) => (
              <Tr key={t.transaction_uid}>
                <Td>{i + 1}</Td>
                <Td layerStyle='table-cell' textStyle='table-cell'>
                  {date(t.transaction_date_time)}
                </Td>
                <Td layerStyle='table-cell'>
                  <VStack spacing={0.5} alignItems='flex-start'>
                    <Text textStyle='table-cell'>{t.offer_name}</Text>
                    <Text textStyle='table-cell' color='blackAlpha.500'>
                      {t.brand_name}
                    </Text>
                  </VStack>
                </Td>
                <Td layerStyle='table-cell' textStyle='table-cell'>
                  {t.card}
                </Td>
                <Td layerStyle='table-cell' textStyle='table-cell'>
                  {formatCurrency(t.transaction_amount, region)}
                </Td>
                <Td layerStyle='table-cell' textStyle='table-cell'>
                  {formatCurrency(t.user_cashback, region)}
                </Td>
                <Td layerStyle='table-cell' textStyle='table-cell'>
                  {status(t.status)}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      {showMore && (
        <Box p={6} width='fit-content' mx='auto'>
          <Button
            variant='unstyled'
            onClick={onShowMore}
            backgroundColor='brand.primary'
            color='white'
            p={3}
            lineHeight={1}
          >
            {t.seeMoreCta}
          </Button>
        </Box>
      )}
    </>
  );
}

TransactionsTable.propTypes = {
  transactions: PropTypes.arrayOf(PropTypes.object),
  showMore: PropTypes.bool,
  onShowMore: PropTypes.func,
};
