import {
  Box,
  Button,
  CircularProgress,
  Heading,
  SimpleGrid,
  Spinner,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PageContainer } from '~/components/page-container';
import useBrandsApi from '~/hooks/useBrandsApi';
import { useCategories } from '~/hooks/useCategories';
import { useTranslations } from '~/hooks/useTranslations';
import { IMPRESSION, QUERY_PARAMS } from '~/lib/constants';
import { chunkedArray } from '~/lib/helpers';
import { useGoogleAnalytics } from '~/lib/state/contexts/GoogleAnalyticsProvider';
import { Brand } from '~/pages/brands/brand';

export function SearchList() {
  const { genericTranslation: t, brandsTranslation: b } = useTranslations();

  const { categories } = useCategories();

  const [searchParams] = useSearchParams();

  const categoryId = Number(searchParams.get(QUERY_PARAMS.CATEGORY_ID)) || '';
  const searchText = searchParams.get(QUERY_PARAMS.SEARCH_TEXT) || '';
  const [pageSize, setPageSize] = useState(108);

  const { isBrandsLoading, brands, hasMoreBrands } = useBrandsApi({
    categoryId,
    searchText,
    pageSize,
  });

  const activeCategory = categories.find((c) => c.category_id === categoryId);

  const { logEvent } = useGoogleAnalytics();

  const responsive = useBreakpointValue(
    {
      base: { columns: 1 },
      sm: { columns: 2 },
      md: { columns: 3 },
      lg: { columns: 4 },
      xl: { columns: 4 },
    },
    {
      fallback: 'md',
      ssr: false,
    }
  );

  const onLoadMoreBrands = () => {
    setPageSize((prev) => prev + 16);
  };

  const searchLabel = useMemo(() => {
    if (searchText?.trim()) {
      return activeCategory
        ? b.searchResultsForIn(searchText, activeCategory.category)
        : b.searchResultsFor(searchText);
    }
    return activeCategory ? b.searchResultsIn(activeCategory.category) : b.searchResults;
  }, [activeCategory, searchText, b]);

  const brandItems = Array.from(brands.values());
  const isLoading = isBrandsLoading && !brandItems.length;
  const noBrandsFound = !isBrandsLoading && brands && !brandItems.length;

  useEffect(() => {
    if (logEvent) {
      const offerUids = brandItems.flatMap((brand) => brand.offers.map((offer) => offer.offer_uid));

      const chunksAdvertiserOfferUids = {};

      chunkedArray(offerUids, 99).forEach((chunk, i) => {
        chunksAdvertiserOfferUids[`offer_ids_${i + 1}`] = chunk;
      });

      logEvent(IMPRESSION, {
        page: 'search_page',
        category_id: activeCategory?.category_id ?? null,
        category_name: activeCategory?.category ?? null,
        search_phrase: searchText,
        advertiser_ids: brandItems.map((brand) => brand.brand_uid),
        ...chunksAdvertiserOfferUids,
      });
    }
  }, [activeCategory, brandItems, logEvent, searchText]);

  return (
    <PageContainer>
      <Heading
        sx={{
          alignSelf: 'flex-start',
          fontSize: '1.75rem',
          color: 'neutral.primary',
          textAlign: 'start',
          marginBottom: 7,
        }}
      >
        {searchLabel}
      </Heading>
      {isLoading ? (
        <Box>
          <CircularProgress size={8} isIndeterminate color='brand.primary' />
        </Box>
      ) : (
        <SimpleGrid columns={responsive.columns} spacing={5} width='100%'>
          {brandItems.map((brand) => (
            <Brand key={brand.brand_uid} brand={brand} />
          ))}
        </SimpleGrid>
      )}
      {hasMoreBrands && !isLoading && (
        <Button
          isDisabled={isBrandsLoading}
          onClick={onLoadMoreBrands}
          alignSelf='center'
          marginTop={16}
        >
          {isBrandsLoading ? <Spinner color='white' /> : t.loadMore}
        </Button>
      )}
      {noBrandsFound && (
        <Box>
          <Text>{b.noBrands}</Text>
        </Box>
      )}
    </PageContainer>
  );
}
