import { Heading, HStack, Stack, Text } from '@chakra-ui/react';
import { TicketsPerforatedIcon } from '~/components/icons';

export const PromoHeader = () => (
  <Stack
    direction={{ base: 'column', md: 'row' }}
    justifyContent='space-between'
    alignItems='center'
  >
    <HStack spacing={3} alignItems='center'>
      <TicketsPerforatedIcon color='primary_color' />
      <Heading fontSize={['16px', '20px']} color='primary_color' fontWeight={600} as='h3'>
        Promo Codes
      </Heading>
    </HStack>
    <Text color='gray.500' fontSize='sm'>
      Copy and paste the code at checkout
    </Text>
  </Stack>
);
