import { merge } from 'lodash-es';
import { useMemo } from 'react';
import { useConfig } from '~/hooks/useConfig/useConfig';
import {
  accountTranslation,
  brandsTranslation,
  financeTranslation,
  genericTranslation,
  giftCardTranslation,
  landingTranslation,
  rewardsTranslation,
} from '~/translations';

export const useTranslations = () => {
  const config = useConfig();
  const { translations: configTranslations } = config;

  const translations = useMemo(() => {
    const result = {};
    result.genericTranslation = merge(genericTranslation, configTranslations?.generic ?? {});
    result.brandsTranslation = merge(brandsTranslation, configTranslations?.brands ?? {});
    result.giftCardTranslation = merge(giftCardTranslation, configTranslations?.giftCard ?? {});
    result.landingTranslation = merge(landingTranslation, configTranslations?.landing ?? {});
    result.rewardsTranslation = merge(rewardsTranslation, configTranslations?.rewards ?? {});
    result.accountTranslation = merge(accountTranslation, configTranslations?.account ?? {});
    result.financeTranslation = merge(financeTranslation, configTranslations?.finance ?? {});

    return result;
  }, [configTranslations]);

  return translations;
};
