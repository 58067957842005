import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import { useConfig } from '~/hooks/useConfig/useConfig';
import { formatCurrency, getRegion } from '~/lib/helpers';

export function TransactionCard({ transaction }) {
  const config = useConfig();
  const region = getRegion(config);

  return (
    <Box layerStyle='table-card'>
      <Box layerStyle='table-card-top'>
        <HStack gap={0} alignItems='center'>
          <Box flexBasis='66%'>
            <Text textStyle='table-card-prop-head'>Offer Name</Text>
            <Text textStyle='table-card-text'>{transaction.offer_name}</Text>
          </Box>
          <Box flexBasis='33%'>
            <VStack w='100%' gap={1} alignItems='flex-end'>
              <Text textStyle='table-card-prop-head'>Cashback</Text>
              <Text textStyle='table-card-text'>
                {formatCurrency(transaction.user_cashback, region)}
              </Text>
            </VStack>
          </Box>
        </HStack>
      </Box>
      <Box layerStyle='table-card-bottom'>
        <HStack gap={0} alignItems='center'>
          <Box flexBasis='66%'>
            <VStack gap={1} alignItems='flex-start'>
              <Text textStyle='table-card-prop-head'>Card Number</Text>
              <Text textStyle='table-card-text'>{transaction.card ?? '---'}</Text>
            </VStack>
          </Box>
          <Box flexBasis='33%'>
            <VStack gap={1} alignItems='flex-end'>
              <Text textStyle='table-card-prop-head'>Amount</Text>
              <Text textStyle='table-card-text'>
                {formatCurrency(transaction.transaction_amount, region)}
              </Text>
            </VStack>
          </Box>
        </HStack>
      </Box>
    </Box>
  );
}

TransactionCard.propTypes = {
  transaction: PropTypes.object,
};
