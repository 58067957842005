import {
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import { ActivatedIcon } from '~/components/icons/activated-icon';
import { FinfareLogoIcon } from '~/components/icons/finfare-logo-icon';
import { useConfig } from '~/hooks/useConfig/useConfig';
import { useTranslationHelpers } from '~/hooks/useTranslationHelpers';
import { useTranslations } from '~/hooks/useTranslations';
import { OFFER_TYPE } from '~/lib/constants';

export function GoingToBrandUrlPopup({
  brand,
  offer,
  hasCommissionGroups,
  isOpen,
  onClose,
  ...props
}) {
  const { brandsTranslation } = useTranslations();
  const { offerAmountText } = useTranslationHelpers();
  const {
    publisher: { region },
  } = useConfig();
  const responsive = useBreakpointValue({
    base: {
      maxWidth: '100%',
      padding: '36px 48px',
      whitespace: 'unset',
    },
    md: {
      maxWidth: '600px',
      padding: '72px 96px',
      whitespace: 'nowrap',
    },
    fallback: 'base',
  });

  const isOnlyVoucherCode = offer.offer_type === OFFER_TYPE.VOUCHER_CODE;

  return (
    <Modal data-role='modal-container' isOpen={isOpen} onClose={onClose} isCentered {...props}>
      <ModalOverlay />
      <ModalContent
        data-role='modal-content'
        display='flex'
        flexDir='column'
        justifyContent='center'
        alignItems='center'
        borderRadius='24px'
        backgroundColor='#fff'
        shadow='xl'
        padding={responsive.padding}
        maxWidth={responsive.maxWidth}
      >
        <ModalCloseButton />
        <ModalBody
          data-role='modal-body'
          layerStyle='modal-body'
          display='flex'
          flexDir='column'
          justifyContent='center'
          alignItems='center'
          paddingY={8}
          maxWidth='100%'
        >
          <FinfareLogoIcon style={{ height: '60px', width: 'auto' }} />
          <Text width='100%' textStyle='merchant-navigation'>
            {brand.name}
          </Text>
          <HStack alignItems='center' justifyContent='center' pt='24px'>
            <ActivatedIcon style={{ width: '32px', height: '32px', marginRight: '10px' }} />
            <Heading variant='offer-main' display='flex' width='100%' justifyContent='center'>
              {isOnlyVoucherCode
                ? ''
                : hasCommissionGroups
                  ? `${brandsTranslation.merchantNav.preOfferText.hasCommissionGroups} `
                  : `${brandsTranslation.merchantNav.preOfferText.noCommissionGroups} `}
              {isOnlyVoucherCode
                ? 'Promo code discount'
                : offerAmountText({
                    offerAmount: brand.max_customer_offer,
                    region,
                    rewardType: offer.reward_type,
                  })}
            </Heading>
          </HStack>
          <Text width='100%' textStyle='merchant-navigation'>
            {brandsTranslation.merchantNav.activated}
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

GoingToBrandUrlPopup.propTypes = {
  brand: PropTypes.object,
  hasCommissionGroups: PropTypes.bool,
  isOpen: PropTypes.bool,
  offer: PropTypes.object,
  onClose: PropTypes.func,
};
